import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useActiveColorMap, useActiveThemeColors, useFlags } from 'src/hooks'
import { v4 as uuidv4 } from 'uuid'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { IChartAltered } from './types'
import { chartStyles } from './styles'
import { ColorMapSchema } from 'src/types/api/requestObjects'
import { colors, theme } from 'src/theme'
import { isContainingCssVariable } from 'src/services/colorServices'

export const ChartXYLine: React.FC<IChartAltered> = React.memo(
  ({
    series,
    options,
    size,
    width,
    height,
    backgroundColor,
    opacity,
    className,
    dataAttr,
  }) => {
    const chartId = uuidv4()
    const colorMap = useActiveColorMap({})
    const activeThemeColors = useActiveThemeColors()
    const flags = useFlags()

    const chartXYLineChartRef = useRef<am5xy.XYChart | null>(null)
    const chartXYLineSeriesRef = useRef<am5xy.LineSeries | null>(null)
    const chartXYLineLegendRef = useRef<am5.Legend | null>(null)
    const chartXYLineXAxisRef =
      useRef<am5xy.CategoryAxis<am5xy.AxisRenderer> | null>(null)
    const chartXYLineYAxisRef =
      useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null)
    const chartXYLineRootRef = useRef<am5.Root | null>(null)
    const [chartRendered, setChartRendered] = useState(false)

    const chartColors = useMemo(
      () =>
        (colorMap?.charts as ColorMapSchema[])
          ?.flatMap((value) => value.default.colors)
          .map((color) => {
            if (color.includes('first'))
              return activeThemeColors?.first ?? colors.black.DEFAULT
            if (color.includes('second'))
              return activeThemeColors?.second ?? colors.black.DEFAULT
            if (color.includes('third'))
              return activeThemeColors?.third ?? colors.black.DEFAULT
            if (color.includes('fourth'))
              return activeThemeColors?.fourth ?? colors.black.DEFAULT
            if (color.includes('wht')) return '#ffffff'
            if (color.includes('blck')) return '#000000'

            return color.startsWith('#') ||
              isContainingCssVariable({ text: color })
              ? color
              : '#' + color
          }),
      [colorMap?.charts, activeThemeColors],
    )
    const DEFAULT_COLORS = useMemo(() => Object.values(theme.colors.data), [])

    useLayoutEffect(() => {
      if (!series.length) return

      const root = am5.Root.new(chartId)
      root._logo?.dispose()
      root.container.set(
        'background',
        am5.Rectangle.new(root, {
          fillOpacity: 0,
        }),
      )

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          layout: root.verticalLayout,
          paddingBottom: options.labels.y ? 78 : 48,
          paddingLeft: options.labels.x ? 78 : 48,
          paddingRight: 48,
          paddingTop: 48,
          ...(width ? { width: size?.width || width } : {}),
          ...(height ? { height: size?.height || height } : {}),
        }),
      )

      chart.children.unshift(
        am5.Label.new(root, {
          text: options.title,
          paddingBottom: 50,
          fontSize: options.titleSize,
        }),
      )

      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'name',
          renderer: am5xy.AxisRendererX.new(root, {}),
          paddingTop: 24,
        }),
      )
      xAxis.data.setAll(series[0].values)
      xAxis.children.push(
        am5.Label.new(root, {
          text: options.labels.showX ? options.labels.x : '',
          x: am5.p50,
          centerX: am5.p50,
        }),
      )

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      )

      yAxis.children.push(
        am5.Label.new(root, {
          text: options.labels.showY ? options.labels.y : '',
          x: options.labels.y ? -30 : 0,
          y: am5.p50,
          centerY: am5.p50,
          rotation: -90,
        }),
      )

      if (!options.showGrids) {
        yAxis.get('renderer').grid.template.set('forceHidden', true)
        xAxis.get('renderer').grid.template.set('forceHidden', true)
      }

      series.forEach(({ label, values }, index) => {
        const currentSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: label,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            categoryXField: 'name',
            maskBullets: false,
            fill: am5.color(
              chartColors?.length
                ? chartColors[index + 1]
                : DEFAULT_COLORS[index + 1],
            ),
            stroke: am5.color(
              chartColors?.length
                ? chartColors[index + 1]
                : DEFAULT_COLORS[index + 1],
            ),
          }),
        )
        currentSeries.strokes.template.setAll({
          strokeWidth: options.stroke.width,
          strokeDasharray: [options.stroke.width * 4, options.stroke.width * 2],
        })

        currentSeries.bullets.push(() =>
          am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: options.bullet.size,
              fill: currentSeries.get('fill'),
            }),
          }),
        )

        currentSeries.data.setAll(values)
        chartXYLineSeriesRef.current = currentSeries
      })

      chartXYLineChartRef.current = chart
      chartXYLineXAxisRef.current = xAxis
      chartXYLineYAxisRef.current = yAxis
      chartXYLineRootRef.current = root

      xAxis.data.setAll(series[0].values)

      // Add legend
      if (options.legends) {
        const legend = chart.children.push(
          am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            paddingTop: 36,
            paddingLeft: 36,
          }),
        )
        legend.data.setAll(chart.series.values)
        legend.markers.template.setAll({
          width: options.bullet.marker,
          height: options.bullet.marker,
        })
        legend.markerRectangles.template.setAll({
          cornerRadiusTL: 8,
          cornerRadiusTR: 8,
          cornerRadiusBL: 8,
          cornerRadiusBR: 8,
        })
        chartXYLineLegendRef.current = legend
      }

      setChartRendered(true)

      return () => {
        root.dispose()
      }
    }, [])

    useEffect(() => {
      if (
        !chartXYLineChartRef.current ||
        !chartXYLineChartRef.current!.series.values[0] ||
        !chartRendered
      ) {
        return
      }
      chartXYLineChartRef.current!.series.values.forEach((value, index) => {
        // Set lines color on series
        value.setAll({
          fill: am5.color(chartColors[index + 1]),
          stroke: am5.color(chartColors[index + 1]),
        })

        // Set Bullet colors on lines
        value.bulletsContainer.children.each((bullet) => {
          // @ts-expect-error fill not typed
          bullet?.set('fill', am5.color(chartColors[index + 1]))
        })

        // Set legend marker colors
        chartXYLineLegendRef.current?.markers.values.forEach(
          (legend, legIndex) => {
            legend.children.values.forEach((value) => {
              value.setAll({
                // @ts-expect-error fill not typed
                fill: am5.color(chartColors[legIndex + 1]),
              })
            })
          },
        )
      })
    }, [chartColors])

    const [jsonOptions, setJsonOptions] = useState('')
    useEffect(() => {
      if (!chartRendered || jsonOptions === JSON.stringify(options)) return
      setJsonOptions(JSON.stringify(options))
      chartXYLineChartRef.current?.set(
        'paddingBottom',
        options.labels.y ? 78 : 48,
      )
      chartXYLineChartRef.current?.set(
        'paddingLeft',
        options.labels.x ? 78 : 48,
      )
      chartXYLineChartRef.current?.set('paddingTop', 48)
      chartXYLineChartRef.current?.set('paddingRight', 48)

      // Update title & titleSize
      const labelIndex = chartXYLineChartRef.current?.children.values.findIndex(
        (value) => value.className === 'Label',
      )
      if (labelIndex !== undefined) {
        chartXYLineChartRef.current?.children.values[labelIndex].set(
          // @ts-expect-error text not typed
          'text',
          options.title,
        )
        chartXYLineChartRef.current?.children.values[labelIndex].set(
          // @ts-expect-error text not typed
          'fontSize',
          options.titleSize,
        )
      }

      // show & hide Grid lines
      if (!options.showGrids) {
        chartXYLineYAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', true)
        chartXYLineXAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', true)
      } else {
        chartXYLineYAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', false)
        chartXYLineXAxisRef.current
          ?.get('renderer')
          .grid.template.set('forceHidden', false)
      }

      // show & hide Legends
      if (options.legends) {
        chartXYLineLegendRef.current?.show()
      } else {
        chartXYLineLegendRef.current?.hide()
      }

      // show & hide X Axis Label
      const labelXIndex =
        chartXYLineXAxisRef.current?.children.values.findIndex(
          (value) => value.className === 'Label',
        )
      if (options.labels.showX) {
        if (labelXIndex !== undefined) {
          chartXYLineXAxisRef.current?.children.values[labelXIndex].show()
          chartXYLineXAxisRef.current?.children.values[labelXIndex].set(
            // @ts-expect-error text not typed
            'text',
            options.labels.x,
          )
        }
      } else {
        if (labelXIndex !== undefined)
          chartXYLineXAxisRef.current?.children.values[labelXIndex].hide()
      }

      // show & hide Y Axis Label
      const labelYIndex =
        chartXYLineYAxisRef.current?.children.values.findIndex(
          (value) => value.className === 'Label',
        )
      if (options.labels.showY) {
        if (labelYIndex !== undefined) {
          chartXYLineYAxisRef.current?.children.values[labelYIndex].show()
          chartXYLineYAxisRef.current?.children.values[labelYIndex].set(
            // @ts-expect-error text not typed
            'text',
            options.labels.y,
          )
        }
      } else {
        if (labelYIndex !== undefined)
          chartXYLineYAxisRef.current?.children.values[labelYIndex].hide()
      }
    }, [options])

    useEffect(() => {
      if (!chartRendered) return

      chartXYLineChartRef.current!.series.clear()
      series.forEach(({ label, values }, index) => {
        // Update series dynamically
        const currentSeries = chartXYLineChartRef.current!.series.push(
          am5xy.LineSeries.new(chartXYLineRootRef.current!, {
            name: label,
            xAxis: chartXYLineXAxisRef.current!,
            yAxis: chartXYLineYAxisRef.current!,
            valueYField: 'value',
            categoryXField: 'name',
            maskBullets: false,
            fill: am5.color(
              chartColors?.length
                ? chartColors[index + 1]
                : DEFAULT_COLORS[index + 1],
            ),
            stroke: am5.color(
              chartColors?.length
                ? chartColors[index + 1]
                : DEFAULT_COLORS[index + 1],
            ),
          }),
        )

        currentSeries.strokes.template.setAll({
          strokeWidth: options.stroke.width,
          strokeDasharray: [options.stroke.width * 4, options.stroke.width * 2],
        })

        currentSeries.bullets.push(() =>
          am5.Bullet.new(chartXYLineRootRef.current!, {
            sprite: am5.Circle.new(chartXYLineRootRef.current!, {
              radius: options.bullet.size,
              fill: currentSeries.get('fill'),
            }),
          }),
        )

        currentSeries.data.setAll(values)
      })
    }, [series])

    const activeBackgroundColor = useMemo(() => {
      if (!flags.FE_617_CHART_IMPROVEMENTS) {
        if (chartColors?.length) {
          return chartColors[0]
        } else {
          return colors.white.DEFAULT
        }
      }

      if (!options.showBackground) {
        return 'transparent'
      } else if (backgroundColor) {
        return backgroundColor.colors[0]
      } else if (chartColors?.length) {
        return chartColors[0]
      } else {
        return colors.white.DEFAULT
      }
    }, [
      flags.FE_617_CHART_IMPROVEMENTS,
      options.showBackground,
      backgroundColor,
      chartColors,
    ])

    return (
      <div
        id={chartId}
        css={chartStyles({
          width,
          height,
          bgColor: activeBackgroundColor,
          opacity,
        })}
        className={className}
        {...dataAttr}
        style={{
          width: width ? `${width}px` : '100%',
          height: height ? `${height}px` : '100%',
        }}
      />
    )
  },
)

ChartXYLine.displayName = 'ChartXYLine'
