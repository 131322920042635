export const texts = {
  // Comum
  '': '',
  'common.decktopus': 'Decktopus',
  'common.decks': 'Apresentações',
  'common.free': 'Grátis',
  'common.description': 'Descrição',
  'common.name': 'Nome',
  'common.email': 'email',
  'common.full_name': 'nome completo',
  'common.role': 'Função',
  'common.dashboard': 'Painel de Controle',
  'common.analytics': 'Análises',
  'common.responses': 'Respostas',
  'common.read_only': 'Somente Leitura',
  'common.read_write': 'Leitura & Escrita',
  'common.last': 'Último',
  'common.first': 'Primeiro',
  'common.title_asc': 'Título (A-Z)',
  'common.title_desc': 'Título (Z-A)',
  'common.recent': 'Recentemente Modificado',
  'common.oldest': 'Mais Antigo Modificado',
  'common.personal': 'Pessoal',
  'common.date': 'Data',
  'common.auto': 'Auto',
  'common.rate': 'Avaliação',
  'common.text': 'Texto',
  'common.value': 'Valor',
  'common.empty': 'Vazio',
  'common.or': 'ou',
  'common.and': 'e',
  'common.email_address': 'Endereço de Email',
  'common.password': 'Senha',
  'common.organization': 'Organização',
  'common.free_uppercased': 'GRÁTIS',
  'common.design': 'Design',
  'common.access': 'Acesso',
  'common.required': 'Requerido',
  'common.button': 'Botão',
  'common.template_name': 'Nome do Modelo',
  'common.language': 'Idioma',
  'common.texts': 'Textos',

  'common.files': '{{count}} arquivos',
  'common.files_zero': '{{count}} arquivo',
  'common.files_one': '{{count}} arquivo',
  'common.files_other': '{{count}} arquivos',

  'common.slides': '{{count}} slides',
  'common.slides_zero': '{{count}} slide',
  'common.slides_one': '{{count}} slide',
  'common.slides_other': '{{count}} slides',

  'common.actions.change': 'Alterar',
  'common.actions.close': 'Fechar',
  'common.actions.complete': 'Completar',
  'common.actions.back': 'Voltar',
  'common.actions.reset': 'Redefinir',
  'common.actions.delete': 'Excluir',
  'common.actions.create': 'Criar',
  'common.actions.share': 'Compartilhar',
  'common.actions.search': 'Pesquisar',
  'common.actions.search_with_name': 'Pesquisar {{name}}',
  'common.actions.check': 'Verificar',
  'common.actions.connect': 'Conectar',
  'common.actions.export': 'Exportar',
  'common.actions.download': 'Baixar',
  'common.actions.sort_by': 'Ordenar por',
  'common.actions.edit': 'Editar',
  'common.actions.save': 'Salvar',
  'common.actions.cancel': 'Cancelar',
  'common.actions.add': 'Adicionar',
  'common.actions.remove': 'Remover',
  'common.actions.rename': 'Renomear',
  'common.actions.discard': 'Descartar',
  'common.actions.approve': 'Aprovar',
  'common.actions.next': 'Próximo',
  'common.actions.move_to': 'Mover para',
  'common.actions.play': 'Reproduzir',
  'common.actions.duplicate': 'Duplicar',
  'common.actions.activate': 'Ativar',
  'common.actions.save_changes': 'Salvar Alterações',
  'common.actions.paste': 'Colar',
  'common.actions.upload': 'Carregar',
  'common.actions.submit': 'Enviar',
  'common.actions.listen_voice': 'Ouvir Voz',
  'common.actions.record_voice': 'Gravar Voz',
  'common.actions.lets_start': 'Vamos Começar',
  'common.actions.leave': 'Sair',
  'common.actions.upload_picture': 'Carregar Imagem',
  'common.actions.remove_picture': 'Remover Imagem',
  'common.actions.select': 'Selecionar',
  'common.actions.preview': 'Pré-visualizar',

  'common.informative.copied': 'Copiado!',
  'common.informative.click_here': 'clique aqui.',
  'common.informative.coming_soon': 'Em Breve',
  'common.informative.fail': 'Falha',
  'common.informative.processing': 'Processando',
  'common.informative.thanks': 'Obrigado!',
  'common.informative.done': 'Concluído',
  'common.informative.payment_successful': 'Pagamento Bem-sucedido',
  'common.informative.your_deck_is_ready': 'Seu deck está pronto.',
  'common.informative.ai_loading_text':
    'Criando sua obra-prima, só um momento...',
  'common.informative.ai_error_text':
    'Estamos enfrentando uma demanda excepcionalmente alta.',
  'common.informative.wait_on_dashboard': 'Aguarde no Painel',
  'common.informative.back_to_first': 'Voltar para o Primeiro',
  'common.informative.ai_high_demand_text':
    'Seu deck não pôde ser criado. Estamos enfrentando uma demanda excepcionalmente alta.',
  'common.informative.error_start_recording': 'Erro ao iniciar a gravação:',
  'common.informative.error_stop_recording': 'Erro ao parar a gravação:',
  'common.informative.error_changing_language':
    'Ocorreu um problema ao mudar o idioma, entre em contato com o suporte ao cliente',
  'common.informative.large_media_file':
    'Ops! Seu arquivo de mídia tem mais de 5MB. Selecione um arquivo menor para uma experiência mais suave.',
  'common.informative.captcha_error':
    'Falha na verificação do Captcha, tente novamente.',
  'common.informative.captcha_expired': 'Captcha expirado, tente novamente',
  'common.informative.captcha_challange_expired':
    'Desafio do Captcha expirado, tente novamente.',

  'common.directions.left': 'Esquerda',
  'common.directions.middle': 'Meio',
  'common.directions.right': 'Direita',
  'common.directions.up': 'Cima',
  'common.directions.center': 'Centro',
  'common.directions.down': 'Baixo',

  'common.nth': '{{count}}º',
  'common.nth_one': '{{count}}º',
  'common.nth_two': '{{count}}º',
  'common.nth_three': '{{count}}º',
  'common.nth_other': '{{count}}º',

  'common.files.media': 'Mídia',
  'common.files.video': 'vídeo',
  'common.files.image': 'Imagem',
  'common.files.gif': 'Gif',
  'common.files.icon': 'Ícone',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',

  'common.roles.owner': 'Proprietário',
  'common.roles.admin': 'Administrador',
  'common.roles.member': 'Membro',
  'common.roles.pending': 'Pendente',

  'common.plan_period.monthly': 'Mensal',
  'common.plan_period.annually': 'Anual',

  'common.plan_card.month': ' / mês',

  'common.seats': 'assento',
  'common.seats_zero': 'assento',
  'common.seats_one': 'assento',
  'common.seats_other': 'assentos',

  'common.deck.add_slide': 'Adicionar Slide',
  'common.deck.duplicate_slide': 'Duplicar Slide',
  'common.deck.delete_slide': 'Excluir Slide',
  'common.deck.undo': 'Desfazer',
  // Comum termina

  // Página de Login
  'login.welcome': 'Bem-vindo!',
  'login.session_expired': 'Sessão expirada, faça login novamente.',
  'login.continue_with_google': 'Continuar com o Google',
  'login.login': 'Entrar',
  'login.logout': 'Sair',
  'login.signup': 'Inscrever-se',
  'login.dont_have_an_account': 'Não tem uma conta?',
  'login.forgot_password': 'Esqueceu sua senha?',
  'login.title1': '2 Milhões',
  'login.text1': 'Usuários ao redor do mundo',
  'login.title2': '64%',
  'login.text2': 'Tempo economizado preparando apresentações com IA',
  'login.title3': '412 mil',
  'login.text3': 'Apresentações criadas em média com Decktopus',
  'login.title4': '<1 min',
  'login.text4': 'Tempo médio de resposta do suporte ao cliente',
  'login.incorrect_provider_jwt':
    'Parece que você já se cadastrou com um email e senha. Por favor, faça login usando essas credenciais.',
  'login.incorrect_provider_google':
    'Parece que você já se cadastrou com uma conta do Google. Tente fazer login com o Google para continuar.',
  // Página de Login termina

  // Página Expirada
  'expired.get_started': 'Começar',
  'expired.expire_link_title': 'Este link expirou',
  'expired.expire_link_description':
    'Este link não é mais válido. Entre em contato com sua organização para mais informações.',
  // Página Expirada termina

  // Página de Inscrição
  'signup.terms': 'Termos',
  'signup.privacy_policy': 'Política de Privacidade',
  'signup.full_name': 'Nome Completo',
  'signup.already_member': 'Já é membro?',
  'signup.create_account': 'Criar Conta',
  'signup_page_title': 'Crie sua conta e desbloqueie sua apresentação',
  'signup.create_account_info':
    'Criar uma conta significa que você está de acordo com nossos <0>termos</0> e <1>política de privacidade</1>',
  'signup.banner': 'Você está a um clique da sua apresentação',
  // Página de Inscrição termina

  // Redefinir Senha
  'reset.password_reset': 'Redefinir Senha',
  'reset.back_to': 'Voltar para',
  'reset.send_email': 'Enviar Email',
  'reset.resend': 'Reenviar Email',
  'reset.new_password': 'Nova Senha',
  'reset.password_set': 'Sua senha foi alterada, por favor faça login.',
  'reset.reset_password': 'Redefinir senha',
  'reset.reset_password_info':
    'Digite seu email, e enviaremos as instruções para redefinir sua senha',
  'reset.password_reset_sent': 'Seu email de redefinição de senha foi enviado',
  'reset.change_your_password_info': 'Clique no botão para redefinir sua senha',
  // Redefinir Senha termina

  // Comum das páginas de autenticação
  'auth.site_protection':
    'Este site é protegido pelo hCaptcha e sua <0>Política de Privacidade</0> e <1>Termos de Serviço</1> se aplicam.',
  'auth.terms_of_service': 'Termos de Serviço',
  // Comum das páginas de autenticação termina

  // Página Não Suportada
  'not_supported': 'não suportado',
  'not_supported.title':
    'Infelizmente, o modo de edição ainda não é suportado neste dispositivo. Para a melhor experiência, mude para um computador desktop.',
  // Página Não Suportada termina

  // Webhook
  'webhook.endpoint_url': 'URL do Endpoint',
  'webhook.event_form_responses': 'Evento: Respostas de Formulário',
  'webhook.webhook_delete_warning':
    'Tem certeza de que deseja excluir permanentemente o webhook “<strong>{{name}}</strong>”?',
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Criar Webhook',
  'webhook.webhook_form_general_info':
    'Crie um webhook e envie notificações em tempo real quando qualquer um dos seus decks receber uma submissão de formulário. Adicione sua URL de endpoint e nomeie seu novo webhook.',
  'webhook.webhook_learn':
    'Para saber mais sobre como usar webhooks <0>clique aqui.</0>',
  // Webhook termina

  // Guias do painel principal
  'main_panel.admin': 'Administrador',
  'main_panel.organization': 'Organização',
  'main_panel.profile_settings': 'Configurações de Perfil',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Indique & Ganhe',
  'main_panel.subscription': 'Assinatura',
  'main_panel.onboarding': 'Onboarding',
  'main_panel.logout': 'Sair',
  // Guias do painel principal termina

  // Configurações de Perfil
  'profile.change_account_information': 'Alterar Informações da Conta',
  'profile.edit_your_name': 'Editar seu nome',
  'profile.change_your_password': 'Alterar sua senha',
  'profile.update.save_changes': 'Salvar Alterações',
  'profile.update.image_upload_success': 'Sua foto de perfil foi alterada.',
  'profile.update.name_change_success': 'Seu nome foi alterado.',
  'profile.update.name_min_char_error':
    'O nome não pode ter menos de 3 caracteres.',
  'profile.update.image_upload_error': '{{fileType}} não é suportado.',
  'profile.verification_success': 'Você verificou sua conta com sucesso.',
  // Configurações de Perfil termina

  // Verificação de email
  'verify.verify_your_email': 'Verifique seu email',
  'verify.verify_your_account': 'Verifique sua conta',
  'verify.verify_your_email_info': 'Clique no botão para verificar sua conta.',
  'verify.verification_email_sent': 'Email de verificação enviado.',
  'verify.verification_enter_code': 'Insira seu código',
  'verify.verification_didnt_reveive_email': 'Não recebeu o email? <0>link</0>',
  'verify.verification_click_to_resend': 'Clique para reenviar',
  'verify.verification_email_sent_info':
    'Enviamos um código de verificação para o seu email.',
  // Verificação de email termina

  // Validações
  'validation.min_char':
    '{{name, capitalFirstLetter}} não pode ter menos de {{value}} caracteres.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} não pode ter mais de {{value}} caracteres.',
  'validation.error.required':
    '{{name, capitalFirstLetter}} não pode estar vazio',
  'validation.error.email': 'Endereço de email inválido',
  'validation.error.url': 'Endereço de URL inválido',
  'validation.error.video_url':
    'URL de vídeo inválida. Por favor, insira um link do YouTube ou Vimeo.',
  'validation.validation_user_exists': 'Este usuário já existe',
  // Validações termina

  // Modal de layout
  'layout.save': 'Salvar Layout',
  'layout.save_new': 'Salvar como Novo Layout',
  'layout.name': 'Nome do Layout',
  'layout.blank': 'Em Branco',
  // Modal de layout termina

  // Seletor de espaço de trabalho
  'workspace.personal_hub': 'Hub Pessoal',
  // Seletor de espaço de trabalho termina

  // Modal de inicialização
  'init.category_select_info':
    'Escolha uma categoria de design que se adeque ao conceito da sua apresentação',
  'init.see_templates': 'Ver Modelos',
  'init.start_from_scratch': 'Começar do Zero',
  'init.use_this_template': 'Usar Este Modelo',

  'init.category0': 'Todos',
  'init.category1': 'Negócios',
  'init.category2': 'Proposta',
  'init.category3': 'Curso Online',
  'init.category4': 'Educação',
  'init.category5': 'Comunidade',
  'init.category6': 'Nutrição',
  'init.category7': 'Resumo Executivo',
  'init.category8': 'Estudo de Caso',
  'init.category9': 'Pitch para Startups',
  // Modal de inicialização termina

  // Modal Criar com IA
  'create_with_ai.generate_presentation': 'Gerar Apresentação',
  'create_with_ai.create_with_ai': 'Criar com IA',
  'create_with_ai.prompt_placeholder':
    'Escreva aqui o tópico da sua apresentação!',
  'create_with_ai.audience_placeholder': 'Escreva aqui seu público',
  'create_with_ai.aim_placeholder': 'Escreva seu objetivo',
  'create_with_ai.prompt_title': 'Sobre o que é sua apresentação?',
  'create_with_ai.audience_title': 'Quem é seu público?',
  'create_with_ai.aim_title': 'Qual é seu objetivo para este deck?',
  'create_with_ai.outline_title':
    'Você pode modificar o esboço ou continuar sem mudanças.',
  'create_with_ai.theme_title': 'Qual modelo você gostaria de usar?',
  'create_with_ai.theme_subtitle':
    'Não se preocupe! Você pode mudá-lo a qualquer momento.',
  'create_with_ai.ai_subtitle':
    'Inspire o Deckto com um título. Tente digitar seu tópico de apresentação como: “História de Roma”',
  'create_with_ai.see_examples': 'Ver Exemplos',
  'create_with_ai.hide_examples': 'Esconder Exemplos',
  'create_with_ai.import_pdf': 'Importe seu PDF',
  'create_with_ai.pdf_subtitle':
    'O Deckto transformará seu documento em um deck com slides editados.',
  // Modal Criar com IA termina

  // Modal de importação de PDF
  'pdf_import.import_pdf_file': 'Importe seu arquivo PDF',
  'pdf_import.drag_and_drop_file': 'Arraste e solte seu arquivo',
  'pdf_import.on_your_computer': 'no seu computador',
  'pdf_import.browse_file': 'procurar arquivo',
  'pdf_import.browse_file_on_your_device':
    'Procurar arquivo no seu dispositivo',
  'pdf_import.pdf_size_error':
    'O tamanho do PDF que você enviou é muito maior do que suportamos, tente com um menor.',
  'pdf_import.use_pdf_format':
    'Não foi possível carregar. Use um arquivo no formato .PDF.',
  'pdf_import.drop_the_file_to_upload': 'Solte o arquivo para carregar',
  // Modal de importação de PDF termina

  // Página de Organização
  'organization.organization_profile_update.image_upload_success':
    'A imagem da organização foi alterada.',
  'organization.organization_profile_update.name_and_description_success':
    'Os detalhes da organização foram alterados.',
  'organization.organization_details': 'Detalhes da Organização',
  'organization.remaining_seats': 'Assentos restantes',
  'organization.manage_seats': 'Gerenciar assentos',
  'organization.used_seats':
    '{{occupiedSeats}} de {{totalSeats}} assentos usados',
  'organization.adjust_seat_number': 'Ajustar número de assentos',
  'organization.current_users': 'Usuários Atuais ({{count}}) Custo / 12',
  'organization.added_users': 'Usuários Adicionados ({{count}}) Custo / 12',
  'organization.updated_annual_total': 'Custo Total Atualizado {{period}} / 12',
  'organization.first_charge': 'A primeira cobrança será proporcional.',
  'organization.first_charge_tooltip':
    'A primeira cobrança dos assentos adicionais será calculada de acordo com o número de dias restantes no ciclo de cobrança atual.',
  'organization.reset_invitation': 'Reenviar convite',
  'organization.cancel_invitation': 'Cancelar convite',
  'organization.member_settings': 'Configurações de Membro',
  'organization.add_members': 'Adicionar membros',
  'organization.add_members_title': 'Adicione membros à sua organização',
  'organization.not_enough_seats':
    'Você não tem assentos suficientes para adicionar mais membros. Ajuste o número de assentos na página da organização para abrir espaço.',
  'organization.add_member_information':
    'Você pode sempre convidar membros para sua organização na barra lateral da página "Minha Organização".',
  'organization.organization-details': 'Detalhes da Organização',
  'organization.leave_team_title': 'Sair da Equipe',
  'organization.leave_team_button': 'Sair da organização',
  'organization.leave_team_dialog_text':
    'Ao sair de uma organização, você não poderá mais acessar decks colaborados.',
  'organization.leave_team_text':
    'Se você deixar a equipe, não poderá acessar os conteúdos da organização e os recursos empresariais.',
  'organization.organization_name_label': 'Nome da organização',
  'organization.organization_desc_label': 'Descrição da organização',
  'organization.organization_team_logo': 'Logotipo da equipe',

  'organization.delete_member': 'Excluir Membro',
  'organization.delete_member_title':
    'Tem certeza de que deseja excluir permanentemente “{{name}}”?',
  'organization.delete_member_text':
    '“{{name}}” não poderá ver todos os decks e detalhes da organização',
  'organization.resend_info':
    '{{email}} ainda não aceitou seu convite. Você enviará o convite novamente como um lembrete.',
  'organization.organization_suspended':
    'Esta organização está suspensa. Atualize seu método de pagamento para resolver o problema.',
  'organization.organization_suspended_member':
    'Esta organização está suspensa. Entre em contato com o administrador da organização para mais assistência.',
  // Página de Organização termina

  // Referência
  'refer.invite_friend': 'Convide seu amigo!',
  'refer.invite_friend_text': `Ambos, você e seu amigo ganham <strong>30</strong> créditos.`,
  'refer.referred': 'Enviamos um e-mail para o seu amigo',
  // Referência termina

  // Análises
  'analytics.no_views_yet': 'Nenhuma visualização ainda',
  'analytics.share_this_deck': 'Compartilhe este deck.',
  'analytics.total_views': '{{view}} Visualizações Totais',
  'analytics.total_views_one_slide':
    '{{view}} Visualizações apenas no primeiro slide',
  'analytics.chart_description':
    'Este gráfico representa o desempenho de visualizações do seu deck',
  'analytics.last_time_open': 'Última vez aberto',
  'analytics.views': '{{view}} Visualizações',
  'analytics.openedTimes': 'Aberto {{view}} vezes',
  // Análises termina

  // Assinaturas
  'subscriptions.upgrade': 'Fazer Upgrade',
  'subscriptions.show_more': 'Mostrar mais',
  'subscriptions.show_less': 'Mostrar menos',
  'subscriptions.subscription': 'Assinatura',
  'subscriptions.current_plan': 'Plano Atual',
  'subscriptions.credit_information': 'Informações de Créditos',
  'subscriptions.ai_credits': 'Créditos de IA',
  'subscriptions.choose_plan': 'Escolher Plano',
  'subscriptions.plan_renew_info': 'Seu plano será renovado em {{date}}',
  'subscriptions.plan_cancel_info': 'Seu plano será cancelado em {{date}}',
  'subscriptions.plan_renew_info_free':
    'Para acessar recursos incríveis de IA, faça o upgrade da sua conta',
  'subscriptions.credit_renew_info':
    'Seus créditos serão renovados em {{date}}',
  'subscriptions.credit_cancel_info':
    'Seus créditos podem ser usados até {{date}}',
  'subscriptions.credit_history': 'Histórico de Créditos',
  'subscriptions.credit_history_info':
    'O histórico de créditos reflete os gastos até o início do período de assinatura',
  'subscriptions.subscription_settings': 'Configurações de Assinatura',
  'subscriptions.plan.powerup_info': 'Quero turbinar meu {{plan_name}}',
  'subscriptions.plan.choose_pro': 'Escolher Pro',
  'subscriptions.plan.choose_business': 'Escolher Business',
  'subscriptions.plan.discount_info': 'Economize até 40%',

  'subscriptions.subs_state.pending': 'Pendente',
  'subscriptions.subs_state.action_required': 'Ação Necessária',
  'subscriptions.subs_state.failed': 'Falhou',
  'subscriptions.subs_state.canceled': 'Cancelado',
  'subscriptions.subs_state.active': 'Ativo',
  'subscriptions.plan.popular': 'Popular',
  'subscriptions.billed_annually': 'cobrado anualmente',
  'subscriptions.update_payment': 'Atualizar Método de Pagamento',
  // Assinaturas termina

  // Modal de Upgrade
  'upgrade_modal.upgrade_to_business': 'Upgrade para Business',
  'upgrade_modal.upgrade_to_business_text':
    'Melhore suas apresentações e aumente seu ROI nas apresentações.',
  'subscriptions.plan.cancel_anytime': 'Cancele a qualquer momento',
  'upgrade_modal.refill_your_credits': 'Recarregue seus créditos',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'Mantenha seu ritmo. Faça o upgrade do seu plano para continuar trabalhando.',
  'upgrade_modal.upgrade_modal_subtitle':
    'Use o Decktopus como seu co-piloto de IA para apresentações.',
  'upgrade_modal.choose_plan': 'Escolher um Plano',
  // Modal de Upgrade termina

  // Página do Painel
  'dashboard.create.create_presentation': 'Criar apresentação',
  'dashboard.create.start_from_scratch': 'Começar do zero',
  'dashboard.create.create_with_a_template': 'Criar com um modelo',

  'dashboard.folder.folder': 'Pasta',
  'dashboard.shared_with_me': 'Compartilhado comigo',
  'dashboard.folder.create_new_folder': 'Criar Nova Pasta',
  'dashboard.folder.create_folder': 'Criar Pasta',
  'dashboard.folder.delete_title':
    'Tem certeza de que deseja excluir permanentemente “{{folder_name}}” e {{folder_id}} arquivos nele?',
  'dashboard.folder.delete_text':
    'Os arquivos não podem ser recuperados uma vez excluídos.',
  'dashboard.folder.folder_is_created': 'A pasta foi criada.',
  'dashboard.folder.folder_deleted': 'A pasta foi excluída.',
  'dashboard.folder.type_folder_name': 'Digite o nome da sua pasta aqui.',
  'dashboard.folder.rename': 'Renomear Pasta',
  'dashboard.folder.renamed': 'Pasta renomeada.',
  'dashboard.folder.name': 'nome da pasta',

  'dashboard.card.rename_deck': 'Renomear Deck',
  'dashboard.card.transferring':
    'Seu deck está sendo transferido da versão antiga...',
  'dashboard.card.migration': 'Migrar',
  'dashboard.card.type_deck_name': 'Digite o nome do seu deck aqui.',
  'dashboard.card.deck_name': 'Nome do deck',
  'dashboard.card.deck_renamed': 'Deck renomeado.',
  'dashboard.card.deck_delete_text':
    'Tem certeza de que deseja excluir “{{deckName}}”?',
  'dashboard.card.remove_me_text':
    'Tem certeza de que deseja se remover de “{{deckName}}”?',
  'dashboard.card.remove_me': 'Remover-me',
  'dashboard.card.file_not_recover':
    'Os arquivos não podem ser recuperados uma vez excluídos.',
  'dashboard.card.deck_deleted': 'Deck excluído',
  'dashboard.card.deck_removed': 'Você foi removido do deck',
  'dashboard.card.publish_template': 'Publicar Modelo',
  'dashboard.card.unpublish_template': 'Despublicar Modelo',

  'deck_not_created': 'O deck não pôde ser criado.',

  'dashboard.search.search_my_decks': 'Pesquisar meus decks no Decktopus',
  'dashboard.search.results_for': 'Resultados para “{{searchParam}}”:',
  'dashboard.search.no_results_for':
    'Nenhum resultado encontrado correspondente a <strong>{{searchParam}}</strong>',
  'dashboard.search.try_another_search':
    'Tente ajustar sua pesquisa para encontrar o que você está procurando',
  'dashboard.search.return_dashboard': 'ou volte ao painel',
  'dashboard.search.search_on_decktopus': 'Pesquisar modelos no Decktopus',

  'dashboard.publish.success': 'Seu deck foi publicado com sucesso',
  'dashboard.unpublish.success': 'Seu deck foi despublicado com sucesso',
  'dashboard.publish.error': 'O deck não pôde ser publicado',
  'dashboard.unpublish.error': 'O deck não pôde ser despublicado',
  // Página do Painel termina

  // Respostas de Formulário
  'form_response.form_responses': 'Respostas de Formulário',
  'form_response.table.download_form': 'Baixar Formulário',
  'form_response.table.deleted_question': 'Pergunta Excluída',
  'form_response.no_answers_yet': 'Ainda não há respostas.',
  'form_response.table.avarage_score': 'Pontuação Média',
  // Respostas de Formulário termina

  // Página de Edição
  'edit.component_fix.detail': 'Ajustes finais estão sendo feitos no seu deck.',
  'edit.component_fix.info': 'Por favor, aguarde',
  'edit.slide_menu.layout': 'layout',

  'edit.create_slide.no_custom_layout_text':
    'Você ainda não criou um layout personalizado.',
  'edit.create_slide.no_shared_layout_text':
    'Você não tem nenhum layout compartilhado.',

  'edit.color_picker.select_color': 'Selecione a cor',
  'edit.color_picker.solid': 'Sólido',
  'edit.color_picker.gradient': 'Gradiente',
  'edit.color_picker.theme_colors': 'Cores do Tema',
  'edit.color_picker.recent_colors': 'Cores Recentes',

  'edit.media.modal.image.unsplash_library': 'Biblioteca do Unsplash',
  'edit.media.modal.image.title':
    'Selecione imagem da <0>biblioteca do Unsplash</0>',
  'edit.media.modal.title': 'Selecione {{type}} da biblioteca',
  'edit.media.modal.load_more': 'Carregar Mais',
  'edit.media.modal.no_result': 'Sem resultado',
  'edit.media.modal.initial_text':
    'Por favor, insira um texto para pesquisar {{type}}',
  'edit.media.modal.ai.input': 'descrição',
  'edit.media.modal.select_media': 'Selecione {{ type }}',
  'edit.media.modal.search_media': 'Pesquisar {{ type }}',

  'edit.list.submenu.placeholder': 'múltiplo',
  'edit.list.layout.columns': 'Colunas',
  'edit.list.layout.rows': 'Linhas',

  'edit.create_slide.copy_from_another_deck': 'Copiar de outro deck',
  'edit.header.title': 'Editar Componente de Cabeçalho',

  'edit.elements.embeds': 'Embutidos',
  'edit.elements.interactive_elements': 'Elementos Interativos',
  'edit.elements.see_all': 'Ver Todos',
  'edit.elements.see_less': 'Ver Menos',
  'edit.elements.shapes': 'Formas',
  'edit.elements.tooltip_text':
    'Somente 1 formulário pode ser adicionado a um slide',
  'edit.elements.content_blocks': 'Blocos de Conteúdo',

  'edit.canvas.blank': 'Em Branco',
  'edit.canvas.context_menu.bring_forward': 'Trazer para Frente',
  'edit.canvas.context_menu.send_backward': 'Enviar para Trás',
  'edit.canvas.context_menu.bring_to_front': 'Trazer para o Primeiro Plano',
  'edit.canvas.context_menu.send_to_back': 'Enviar para o Fundo',
  'edit.canvas.context_menu.send_to_background': 'Enviar para o Plano de Fundo',

  'edit.canvas.context_menu.edit_with_ai': 'Editar com IA',
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Resumir',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Corrigir',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Reescrever',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Estender',
  'edit.canvas.context_menu.edit_with_ai.other': 'Outro',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Gerar',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Digite sua solicitação aqui.',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Dê ao Decktopus uma solicitação para melhorar o conteúdo selecionado',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'A descrição não pode ter menos de 3 caracteres.',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    'Não houve nada para corrigir',

  'edit.canvas.paste.processing_image': 'Processando imagem...',
  'edit.canvas.paste.image_pasted': 'Imagem carregada!',

  'edit.ai.create_qa_text':
    'Veja as Perguntas e Respostas especializadas para sua apresentação. As perguntas que podem ser feitas durante sua apresentação e suas respostas estão nesta seção.',
  'edit.ai.create_qa_button': 'Criar Perguntas e Respostas',
  'edit.ai.create_tip_text':
    'As dicas de slides estão nesta seção para proporcionar uma melhor experiência de apresentação. Sugestões de quebra-gelo, gancho e estrutura semelhante a uma história estão aqui para ajudá-lo.',
  'edit.ai.create_tip_button': 'Criar Dicas',
  'edit.ai.create_tips_hover': 'Crie um deck com IA para usar este recurso.',

  'edit.canvas_sidebar.start_show': 'Iniciar Apresentação',
  'edit.canvas_sidebar.start_voice': 'Iniciar Voz',
  'edit.canvas_sidebar.swap_colors': 'Trocar Cores',

  'edit.design.customize_design': 'Personalizar Design',
  'edit.design.font_family': 'Família de Fontes',
  'edit.design.default_fonts_delete':
    'As fontes padrão não podem ser excluídas.',
  'edit.design.upload_custom_font': 'Carregar Fonte Personalizada',
  'edit.design.upload_your_font': 'Carregue sua Fonte Aqui',
  'edit.design.allowed_fonts_text':
    'Por favor, carregue apenas fontes nos formatos .ttf e .otf.',
  'edit.design.custom_font_responsibility':
    'Toda a responsabilidade pelo uso comercial das fontes carregadas é do usuário. O Decktopus não assume nenhuma responsabilidade pelo uso comercial.',
  'edit.design.upload_primary_font': 'Carregar Fonte Primária',
  'edit.design.upload_secondary_font': 'Carregar Fonte Secundária',
  'edit.design.color_palette': 'Paleta de Cores',
  'edit.design.default_color_palettes':
    'As paletas padrão não podem ser excluídas.',

  'edit.preferences.background_color': 'Cor de Fundo',
  'edit.preferences.background_tooltip':
    'Selecione um novo tema abaixo para alterar o fundo.',
  'edit.preferences.background_decor': 'Decoração de Fundo',

  'edit.properties.list.submenu.title': 'Propriedades de Texto e Ícone',
  'edit.properties.list.new_list.add_new': 'Adicionar Novo',

  'edit.properties.quote.alignment': 'Alinhamento da Citação',
  'edit.properties.quote.placeholder': 'Citação',
  'edit.properties.quote.author': 'Autor',
  'edit.properties.quote.size.label.text': 'Texto da Citação',
  'edit.properties.quote.size.label.author': 'Autor',

  'edit.properties.media.image_props': 'Propriedades da Imagem',
  'edit.properties.media.image_opacity': 'Opacidade',
  'edit.properties.media.corner_radius': 'Raio de Canto',

  'edit.properties.icon.styles': 'Estilos',
  'edit.properties.icon.icon': 'Ícone',
  'edit.properties.icon.background': 'Plano de Fundo',

  'edit.properties.data': 'Editar Dados',
  'edit.properties.table': 'Editar Tabela',
  'edit.properties.show_header_column': 'Mostrar Coluna de Cabeçalho',
  'edit.properties.show_header_row': 'Mostrar Linha de Cabeçalho',
  'edit.properties.form.submenu.title': 'Propriedades do Formulário',
  'edit.properties.form.button_text': 'Texto do botão',
  'edit.properties.form.placeholder_text': 'Texto do espaço reservado',
  'edit.properties.form.question_text': 'texto da pergunta',
  'edit.properties.form.choice': 'escolha',
  'edit.properties.form.header': 'Adicione Aqui o Tipo de Formulário',
  'edit.properties.form.header.button_text': 'Texto do Botão',
  'edit.properties.form.option_list': 'Lista de Opções',
  'edit.properties.form.check_list': 'Lista de Verificação',
  'edit.properties.form.free_text': 'Texto Livre',
  'edit.properties.form.submitting_required_tooltip':
    'Se isso estiver ativado, o visualizador não poderá ver os próximos slides até enviar este formulário',

  'edit.properties.button_props.paste_url': 'Cole uma URL do site.',
  'edit.properties.button_props.outlined': 'Contornado',

  'edit.properties.chart_props.show_grid': 'Mostrar Linhas de Grade',
  'edit.properties.chart_props.show_legends': 'Mostrar Legendas',
  'edit.properties.chart_props.settings': 'Configurações',
  'edit.properties.chart_props.opacity': 'Opacidade de Fundo',
  'edit.properties.chart_props.background_color': 'Cor de Fundo',

  'edit.properties.chart_title.placeholder': 'Título do Gráfico',
  'edit.properties.edit_data.error': 'não é um número',

  'edit.properties.x_axis.show_x_axis': 'Mostrar Eixo X',
  'edit.properties.x_axis.x_axis': 'Eixo X',
  'edit.properties.y_axis.show_y_axis': 'Mostrar Eixo Y',
  'edit.properties.y_axis.y_axis': 'Eixo Y',

  'edit.properties.form_choice.choice': 'Escolha',

  'edit.properties.form_props.question': 'Pergunta',
  'edit.properties.form_props.description': 'Descrição',
  'edit.properties.form_props.choice_1': 'Escolha 1',
  'edit.properties.form_props.choice_2': 'Escolha 2',
  'edit.properties.form_props.choice_3': 'Escolha 3',

  'edit.properties.form_question_option.add_choice': 'Adicionar Escolha',
  'edit.properties.form_question_rate.choose_icon': 'Escolher Estilo de Ícone',

  'edit.properties.device.header': 'Link',
  'edit.properties.device.change_device': 'Alterar Dispositivo',
  'edit.properties.device.laptop': 'Laptop',
  'edit.properties.device.tablet': 'Tablet',
  'edit.properties.device.phone': 'Telefone',

  'edit.properties.chart.chart_data': 'Tipo de Gráfico:',

  'edit.properties.shape.placeholder': 'Forma',
  'edit.properties.shape.distort': 'Distorcer',

  'edit.properties.ai_image.create': 'Criar com IA',
  'edit.properties.ai_image.generate_with_ai': 'Gerar uma Imagem com IA',
  'edit.properties.ai_image.describe': 'Descreva sua solicitação para IA',
  'edit.properties.ai_image.generate': 'Gerar',

  'edit.properties.table.placeholder': 'Configurações',

  'edit.properties.text_size.placeholder': 'Múltiplo',
  'edit.properties.text_size.big_text': 'Texto Grande',
  'edit.properties.text_size.title': 'Título',
  'edit.properties.text_size.subtitle': 'Subtítulo',
  'edit.properties.text_size.body': 'Corpo',
  'edit.properties.text_size.label': 'Etiqueta',

  'edit.properties.text_props.header': 'Texto',
  'edit.properties.text_props.reset': 'Redefinir',
  'edit.properties.text_props.fill': 'Preencher',
  'edit.properties.text_props.opacity': 'Opacidade',

  'edit.properties.video_props.video': 'Vídeo',
  'edit.properties.video_props.paste':
    'Cole o link do vídeo do YouTube ou Vimeo.',

  'edit.properties.website_props.website': 'Site',
  'edit.properties.website_props.paste': 'Cole uma URL do site.',

  'edit.properties.alignment.position': 'Posição',

  'edit.properties.list.list_type': 'Tipo de Lista',
  'edit.properties.list.list_direction': 'Direção',
  'edit.properties.list.media_position': 'Posição da Mídia',
  'edit.properties.list.layout': 'Layout',
  'edit.properties.list.colors': 'Cores',
  'edit.properties.list.font_size': 'Tamanho da Fonte',
  'edit.properties.list.size': 'Tamanho',
  'edit.properties.list.icon_frame': 'Moldura do Ícone',
  'edit.properties.list.color.icon': 'Ícone',
  'edit.properties.list.color.icon_frame': 'Moldura',
  'edit.properties.list.color.number': 'Número',
  'edit.properties.list.color.bullet': 'Marcador',
  'edit.properties.list.font_title': 'Título',
  'edit.properties.list.color.title': 'Título',
  'edit.properties.list.color.body': 'Corpo',
  'edit.properties.list.font_body': 'Corpo',
  'edit.properties.list.font_logo': 'Logo',

  'edit.properties.form.form_properties': 'Propriedades do Formulário',
  'edit.properties.form.gdpr_message': 'Mensagem de GDPR',
  'edit.properties.form.submitting_required':
    'Envio do Formulário é Necessário',
  'edit.properties.form.consent': 'Ativar Consentimento GDPR',
  'edit.properties.form.gdpr_tooltip':
    'Se isso estiver ativado, uma caixa de seleção aparecerá acima do botão de envio.',
  'edit.properties.form.gdpr_prop_placeholder':
    'Espaço Reservado de Entrada de Texto Livre',
  'edit.properties.form.option_list_placeholder':
    'Espaço Reservado da Lista de Opções',
  'edit.properties.form.rate_placeholder': 'Espaço Reservado de Avaliação',
  'edit.properties.form.back_button_text': 'Texto do Botão de Voltar',
  'edit.properties.form.next_button_text': 'Texto do Botão de Próximo',
  'edit.properties.form.submit_button_text': 'Texto do Botão de Enviar',
  'edit.properties.form.submit_message': 'Mensagem de Envio',
  'edit.properties.font.select_option': 'Selecionar Opção',

  'edit.add_new_slide_modal.slide_layouts': 'Layouts de Slide',
  'edit.add_new_slide_modal.shared_with_me': 'Compartilhado Comigo',
  'edit.add_new_slide_modal.custom_layouts': 'Layouts Personalizados',
  'edit.add_new_slide_modal.search_slide_type':
    'Pesquise um tipo de slide na sua biblioteca de layouts, ex: “Imagem”',

  'edit.panel.menu_tabs.slide_notes': 'Notas de Slide',
  'edit.panel.menu_tabs.transcripts': 'Transcrições',
  'edit.panel.menu_tabs.tips': 'Dicas',
  'edit.panel.menu_tabs.qa': 'Perguntas & Respostas',
  'edit.panel.menu_tabs.themes': 'Temas',
  'edit.panel.menu_tabs.style': 'Estilo',
  'edit.panel.menu_tabs.colors': 'Cores',

  'edit.panel.preferences': 'Preferências',
  'edit.panel.elements': 'Elementos',
  'edit.panel.notes': 'Notas',
  'edit.panel.ai_assistant': 'Assistente de IA',
  'edit.panel.brand': 'Marca',

  'edit.slide_menu.warning': 'Aviso',
  'edit.slide_menu.warning_text':
    'Você está prestes a substituir um layout existente, deseja continuar?',
  'edit.slide_menu.replace': 'Substituir',

  'edit.properties.zoom_types.fill': 'Preencher',
  'edit.properties.zoom_types.fit': 'Ajustar',
  'edit.properties.zoom_types.distort': 'Distorcer',
  'edit.properties.zoom_types.crop': 'Cortar',

  'edit.definitions.bar_chart.display_name': 'Gráfico de Barras',
  'edit.definitions.bar_chart.name1': 'Engenharia',
  'edit.definitions.bar_chart.name2': 'Marketing',
  'edit.definitions.bar_chart.name3': 'Operações',
  'edit.definitions.body.text': 'Novo Corpo',
  'edit.definitions.body.display_name': 'Este é seu corpo',
  'edit.definitions.bullet_list.display_name': 'Lista de Marcadores',
  'edit.definitions.bullet_list.item_title1': 'Item 1',
  'edit.definitions.bullet_list.item_title2': 'Item 2',
  'edit.definitions.bullet_list.item_title3': 'Item 3',
  'edit.definitions.bullet_list.item_body1': 'Descrição do Item 1',
  'edit.definitions.bullet_list.item_body2': 'Descrição do Item 2',
  'edit.definitions.bullet_list.item_body3': 'Descrição do Item 3',
  'edit.definitions.button.display_name': 'Botão',
  'edit.definitions.button.text': 'Botão',
  'edit.definitions.code.display_name': 'Código',
  'edit.definitions.device.display_name': 'Dispositivo',
  'edit.definitions.donut_chart.display_name': 'Gráfico de Rosca',
  'edit.definitions.donut_chart.label': 'Série 1',
  'edit.definitions.donut_chart.value1': 'Alfa',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Eco',
  'edit.definitions.form.display_name': 'Formulário',
  'edit.definitions.form.title': 'Título do Formulário',
  'edit.definitions.form.button_text': 'Enviar',
  'edit.definitions.form.answers.text': 'Espaço reservado de texto',
  'edit.definitions.form.answers.option': 'Espaço reservado de opção',
  'edit.definitions.form.answers.rate': 'Espaço reservado de avaliação',
  'edit.definitions.form.buttons.next': 'Próximo',
  'edit.definitions.form.buttons.back': 'Voltar',
  'edit.definitions.form.submit': 'Obrigado',
  'edit.definitions.form.gdpr_consent_message':
    'Mensagem de Consentimento GDPR',
  'edit.definitions.form.question1': 'Pergunta 1',
  'edit.definitions.form.question2': 'Pergunta 2',
  'edit.definitions.form.question3': 'Pergunta 3',
  'edit.definitions.form.question2.description': 'Descrição',
  'edit.definitions.form.choice1': 'Escolha 1',
  'edit.definitions.form.choice2': 'Escolha 2',
  'edit.definitions.form.choice3': 'Escolha 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Ícone',
  'edit.definitions.icon_list.display_name': 'Lista de Ícones',
  'edit.definitions.icon_list.title1': 'Item 1',
  'edit.definitions.icon_list.body1': 'Descrição do Item 1',
  'edit.definitions.icon_list.title2': 'Item 2',
  'edit.definitions.icon_list.body2': 'Descrição do Item 2',
  'edit.definitions.icon_list.title3': 'Item 3',
  'edit.definitions.icon_list.body3': 'Descrição do Item 3',
  'edit.definitions.image.display_name': 'Imagem',
  'edit.definitions.image_grid.display_name': 'Grade de Imagens',
  'edit.definitions.image_grid.title1': 'Item 1',
  'edit.definitions.image_grid.body1': 'Descrição do Item 1',
  'edit.definitions.image_grid.title2': 'Item 2',
  'edit.definitions.image_grid.body2': 'Descrição do Item 2',
  'edit.definitions.image_grid.title3': 'Item 3',
  'edit.definitions.image_grid.body3': 'Descrição do Item 3',
  'edit.definitions.image_list.display_name': 'Lista de Imagens',
  'edit.definitions.image_list.title1': 'Item 1',
  'edit.definitions.image_list.body1': 'Descrição do Item 1',
  'edit.definitions.image_list.title2': 'Item 2',
  'edit.definitions.image_list.body2': 'Descrição do Item 2',
  'edit.definitions.image_list.title3': 'Item 3',
  'edit.definitions.image_list.body3': 'Descrição do Item 3',
  'edit.definitions.jumbo.text': 'Novo Texto Jumbo',
  'edit.definitions.jumbo.display_name': 'Este é um texto grande',
  'edit.definitions.label.text': 'Nova Etiqueta',
  'edit.definitions.label.display_name': 'Esta é sua etiqueta',
  'edit.definitions.line_chart.display_name': 'Gráfico de Linha',
  'edit.definitions.line_chart.label1': 'Linha 1',
  'edit.definitions.line_chart.label1.name1': '09 Fev',
  'edit.definitions.line_chart.label1.name2': '10 Fev',
  'edit.definitions.line_chart.label1.name3': '11 Fev',
  'edit.definitions.line_chart.label1.name4': '12 Fev',
  'edit.definitions.line_chart.label1.name5': '13 Fev',
  'edit.definitions.line_chart.label2': 'Linha 2',
  'edit.definitions.line_chart.label2.name1': '09 Fev',
  'edit.definitions.line_chart.label2.name2': '10 Fev',
  'edit.definitions.line_chart.label2.name3': '11 Fev',
  'edit.definitions.line_chart.label2.name4': '12 Fev',
  'edit.definitions.line_chart.label2.name5': '13 Fev',
  'edit.definitions.links.display_name': 'Link',
  'edit.definitions.links.text': 'Texto do Botão',
  'edit.definitions.logo.display_name': 'Logotipo',
  'edit.definitions.logo_list.display_name': 'Lista de Logotipos',
  'edit.definitions.logo_list.title1': 'Logotipo 1',
  'edit.definitions.logo_list.body1': 'Descrição do Logotipo 1',
  'edit.definitions.logo_list.title2': 'Logotipo 2',
  'edit.definitions.logo_list.body2': 'Descrição do Logotipo 2',
  'edit.definitions.logo_list.title3': 'Logotipo 3',
  'edit.definitions.logo_list.body3': 'Descrição do Logotipo 3',
  'edit.definitions.pie_chart.display_name': 'Gráfico de Pizza',
  'edit.definitions.pie_chart.label': 'Série 1',
  'edit.definitions.pie_chart.value1': 'Alfa',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Eco',
  'edit.definitions.quote.display_name': 'Citação',
  'edit.definitions.quote.quote':
    'A imaginação é tudo. Ela é a prévia das atrações da vida que estão por vir.',
  'edit.definitions.quote.author': 'Albert Einstein',
  'edit.definitions.rating.display_name': 'Avaliação',
  'edit.definitions.rating.title': 'Título do Formulário',
  'edit.definitions.rating.button_text': 'Enviar',
  'edit.definitions.rating.placeholder.text': 'Espaço reservado de texto',
  'edit.definitions.rating.placeholder.options': 'Espaço reservado de opções',
  'edit.definitions.rating.placeholder.rate': 'Espaço reservado de avaliação',
  'edit.definitions.rating.next': 'Próximo',
  'edit.definitions.rating.back': 'Voltar',
  'edit.definitions.rating.submit': 'Obrigado',
  'edit.definitions.rating.gdpr_consent': 'Mensagem de Consentimento GDPR',
  'edit.definitions.rating.question_text': 'Avalie sua experiência!',
  'edit.definitions.rating.question_description': 'Descrição',
  'edit.definitions.subtitle.text': 'Novo Subtítulo',
  'edit.definitions.subtitle.display_name': 'Este é seu subtítulo',
  'edit.definitions.showcase.display_name': 'Vitrine',
  'edit.definitions.table.display_name': 'Tabela',
  'edit.definitions.table.header1': 'Categorias',
  'edit.definitions.table.header2': 'Valores 1',
  'edit.definitions.table.header3': 'Valores 2',
  'edit.definitions.table.header4': 'Valores 3',
  'edit.definitions.table.header5': 'Valores 4',
  'edit.definitions.table.row1.value1': 'Categorias 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Categorias 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Categorias 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Categorias 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Linha do Tempo',
  'edit.definitions.timeline.item1': 'Item 1',
  'edit.definitions.timeline.description1': 'Descrição do Item 1',
  'edit.definitions.timeline.item2': 'Item 2',
  'edit.definitions.timeline.description2': 'Descrição do Item 2',
  'edit.definitions.timeline.item3': 'Item 3',
  'edit.definitions.timeline.description3': 'Descrição do Item 3',
  'edit.definitions.title.text': 'Novo Título',
  'edit.definitions.title.display_name': 'Este é seu título',
  'edit.definitions.video.display_name': 'Vídeo',
  'edit.definitions.website.display_name': 'Site',

  'edit.background.options': 'Opções de Fundo',

  'edit.color_mapping.swap_colors': 'Trocar Cores',
  'edit.color_mapping.restore': 'Restaurar para as Cores Padrão',
  'edit.color_mapping.restore_short': 'Restaurar Cores',
  'edit.color_mapping.texts': 'Textos',
  'edit.color_mapping.texts.item1': 'Texto Grande',
  'edit.color_mapping.texts.item2': 'Título',
  'edit.color_mapping.texts.item3': 'Subtítulo',
  'edit.color_mapping.texts.item4': 'Corpo',
  'edit.color_mapping.texts.item5': 'Etiqueta',
  'edit.color_mapping.background': 'Fundo',
  'edit.color_mapping.quote': 'Citação',
  'edit.color_mapping.quote.item1': 'Citação',
  'edit.color_mapping.quote.item2': 'Autor',
  'edit.color_mapping.icons': 'Ícones',
  'edit.color_mapping.icons.item1': 'Ícone',
  'edit.color_mapping.icons.item2': 'Preenchimento de Ícone',
  'edit.color_mapping.lists': 'Listas',
  'edit.color_mapping.lists.item1': 'Marcador de Lista',
  'edit.color_mapping.lists.item2': 'Título da Lista',
  'edit.color_mapping.lists.item3': 'Corpo da Lista',
  'edit.color_mapping.table': 'Tabela',
  'edit.color_mapping.table.item1': 'Conteúdo',
  'edit.color_mapping.table.item2': 'Destaque',
  'edit.color_mapping.buttons': 'Botões',
  'edit.color_mapping.buttons.item1': 'Texto',
  'edit.color_mapping.buttons.item2': 'Preenchimento do Botão',
  'edit.color_mapping.buttons.item3': 'Borda',
  'edit.color_mapping.timeline': 'Linha do Tempo',
  'edit.color_mapping.timeline.item1': 'Barra da Linha do Tempo',
  'edit.color_mapping.timeline.item2': 'Ponto da Linha do Tempo',
  'edit.color_mapping.form': 'Formulário',
  'edit.color_mapping.form.item1': 'Pergunta',
  'edit.color_mapping.form.item2': 'Texto do Botão',
  'edit.color_mapping.form.item3': 'Preenchimento do Botão',
  'edit.color_mapping.form.item4': 'Ícone',
  'edit.color_mapping.chart': 'Gráfico',
  'edit.color_mapping.chart.items': 'Cor',
  'edit.color_mapping.chart.background': 'Fundo do Gráfico',
  'edit.color_mapping.text_highlight': 'Destaque de Texto',
  'edit.color_mapping.text_highlight.item1': 'Destaque',
  'edit.color_mapping.text_highlight.item2': 'Fundo de Destaque',
  'edit.color_mapping.shape': 'Forma',
  'edit.color_mapping.shape.item1': 'Preenchimento da Forma',
  'edit.color_mapping.decoration': 'Decorações',
  'edit.color_mapping.decoration.items': 'Decoração',
  // Página de Edição termina

  // Página do Usuário - Classificação
  'user_classify.business': 'Negócios',
  'user_classify.continue_with_decktopus': 'Continue com Decktopus',
  'user_classify.customer_service': 'Serviço ao Cliente',
  'user_classify.education': 'Educação',
  'user_classify.educator': 'Educador',
  'user_classify.engineering': 'Engenharia',
  'user_classify.finance': 'Finanças',
  'user_classify.friends': 'Amigos',
  'user_classify.google_search': 'Pesquisa Google',
  'user_classify.hr': 'Recursos Humanos',
  'user_classify.legal': 'Jurídico',
  'user_classify.management': 'Gestão',
  'user_classify.other': 'Outro',
  'user_classify.product': 'Produto',
  'user_classify.sales_marketing': 'Vendas e Marketing',
  'user_classify.see_other_options': 'Ver outras opções',
  'user_classify.social_media': 'Mídia Social',
  'user_classify.student': 'Estudante',
  'user_classify.tell_us_about_yourself': 'Conte-nos sobre você',
  'user_classify.classify_question':
    'Com que propósito você está usando o Decktopus?',
  'user_classify.business_question': 'Que tipo de trabalho você faz?',
  'user_classify.how_to_hear_about_us': 'Como você ouviu falar de nós?',
  // Página do Usuário - Classificação termina

  // Modal de Compartilhamento
  'share_modal.embed.code': 'Obter Código de Incorporação',
  'share_modal.share_link': 'Compartilhar Link',
  'share_modal.get_share_link': 'Obter Link de Compartilhamento',
  'share_modal.make_the_share_link_public':
    'Tornar o link de compartilhamento público',
  'share_modal.select_from_list': 'Selecionar da lista de usuários',

  'share_modal.share_link_updated':
    'Link de compartilhamento atualizado com sucesso.',
  'share_modal.invite_people_to_collaborate': 'Convidar pessoas para colaborar',
  'share_modal.invitations_sent': 'Convites enviados',
  'share_modal.organization_deck_permission': 'Permissão da Organização',
  'share_modal.valid_email_message':
    'Por favor, insira um endereço de e-mail válido e pressione enter',
  'share_modal.add_collaborators': 'Adicionar Colaborador',
  'share_modal.not_collaborating_message':
    'Você ainda não está colaborando com ninguém.',
  'share_modal.collaborators': 'Colaboradores',
  'share_modal.can_edit': 'Pode Editar',
  'share_modal.can_view': 'Pode Visualizar',
  'share_modal.embed_code': 'Código de Incorporação',
  'share_modal.collaborated_users_updated':
    'Usuários colaboradores atualizados',
  'share_modal.save_complete': 'Alterações salvas',
  'share_modal.pdf_export': 'Exportar PDF',
  'share_modal.pptx_export': 'Exportar PPTX',
  'share_modal.latest_exports': 'Meus Últimos Exports',

  'share_modal.custom_domain.status': 'Status',
  'share_modal.custom_domain.host': 'Host',
  'share_modal.custom_domain.completed': 'COMPLETO',
  'share_modal.custom_domain.pending': 'PENDENTE',
  'share_modal.custom_domain.record': 'Registro',
  'share_modal.custom_domain.data': 'Dados',
  'share_modal.custom_domain.cname': 'CNAME',
  'share_modal.custom_domain.share_link_placeholder':
    'ex: https://document.seudominio.com',
  'share_modal.custom_domain.domain_successfully': 'Domínio salvo com sucesso.',
  'share_modal.custom_domain.domain_deleted': 'Domínio excluído com sucesso.',
  'share_modal.custom_domain.domain_failed':
    'O domínio ainda não foi verificado. Pode levar até 72 horas dependendo do servidor do seu domínio',
  'share_modal.custom_domain.dns_record':
    'Para conectar seu domínio personalizado ao seu Decktopus, você precisa atualizar seus registros DNS com base na tabela abaixo.',
  'share_modal.custom_domain.add_your_subdomain': 'Adicione seu subdomínio:',
  'share_modal.custom_domain.info': 'Informações',
  'share_modal.custom_domain.verified': 'Verificado',
  'share_modal.custom_domain.unverified': 'Não Verificado',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Conecte Seu Domínio Personalizado',
  // Modal de Compartilhamento termina

  // Página de Administração
  'admin.menu.user_info': 'Informações do Usuário',
  'admin.menu.back_to_search': 'Voltar para Pesquisa',
  'admin.search.welcome_support_panel':
    'Bem-vindo ao <strong>Painel de Suporte</strong>',
  'admin.search.you_can_search_by':
    'Você pode pesquisar por e-mail, id do deck ou chave de compartilhamento para listar o que precisar.',
  'admin.search.share_key': 'Chave de Compartilhamento',
  'admin.search.deck_id': 'ID do Deck',
  'admin.search.user_not_found': 'Usuário não encontrado.',
  'admin.table.e_mail': 'Email',
  'admin.table.delete_user': 'Excluir Usuário',
  'admin.table.created_date': 'Data de Criação',
  'admin.confirm.cannot_undo_delete_user':
    'Você não pode desfazer esta função. Tem certeza de que deseja excluir este usuário?',
  'admin.table.actions': 'Ações',
  'admin.action_menu.login_as_user': 'Entrar como usuário',
  'admin.dashboard.header.back_to_support': 'Voltar ao painel de suporte',
  'admin.dashboard.header.logged_in_as_user': 'Você entrou como um usuário.',
  // Página de Administração termina

  // Itens de menu do componente de Tabela
  'table.add_row_above': 'Adicionar linha acima',
  'table.add_row_below': 'Adicionar linha abaixo',
  'table.add_column_left': 'Adicionar coluna à esquerda',
  'table.add_column_right': 'Adicionar coluna à direita',
  'table.delete_row': 'Excluir linha',
  'table.delete_column': 'Excluir coluna',
  // Itens de menu do componente de Tabela termina

  // Modal de Confirmação de Pagamento
  'payment_confirmation.title': 'Atualize seu plano para {{ planName }}',
  'payment_confirmation.subtitle':
    'Você está a um passo de desfrutar dos recursos exclusivos do Decktopus',
  'payment_confirmation.plan': 'Plano:',
  'payment_confirmation.payment_method': 'Método de Pagamento:',
  'payment_confirmation.card_number': 'Número do Cartão:',
  'payment_confirmation.card_date': 'Data do Cartão:',
  'payment_confirmation.total': 'Total:',
  'payment_confirmation.contact_us':
    'Se você tiver alguma dúvida ou precisar de assistência, sinta-se à vontade para <0>nos contatar.</0>',
  'payment_confirmation.cancel': 'Cancelar Pagamento',
  'payment_confirmation.confirm': 'Confirmar e Pagar',
  'payment_confirmation.seat': 'Assento',
  'payment_confirmation.pro': 'Pro AI',
  'payment_confirmation.business': 'Business AI',
  'payment_confirmation.remaining_line':
    'Tempo restante no {{ planName }} após {{ date }}',
  'payment_confirmation.unused_line':
    'Tempo não utilizado no {{ planName }} após {{ date }}',
  'payment_confirmation.total_tooltip':
    'O pagamento será proporcional com base nos dias restantes no seu ciclo de cobrança atual.',
  // Modal de Confirmação de Pagamento termina

  'common.history': 'Histórico',
  'common.percent': '{{value}}%',
  'common.actions.skip': 'Pular',
  'common.files.pdf_standard': 'PDF Padrão',
  'common.files.pdf_compressed': 'PDF Comprimido',
  'common.files.ppt_standard': 'PPT Padrão',
  'common.files.ppt_compressed': 'PPT Comprimido',
  'create_with_ai.category_title':
    'Escolha uma categoria para sua apresentação.',
  'create_with_ai.category.option1': 'Pitch de Startup',
  'create_with_ai.category.option2': 'Proposta de Negócios',
  'create_with_ai.category.option3': 'Apresentação de Vendas',
  'create_with_ai.category.option4': 'Apresentação de Relatório',
  'create_with_ai.category.option5': 'Educacional',
  'create_with_ai.category.option6': 'Outro',
  'create_with_ai.strength.low':
    'Algumas palavras a mais podem revelar um mundo de insights mais profundos!',
  'create_with_ai.strength.medium':
    'Você está quase lá—adicione um pouco mais para revelar o quadro completo!',
  'create_with_ai.strength.high':
    'Fantástico! Quanto mais contexto você fornecer, mais precisos serão os resultados.',
  'create_with_ai.strength.done':
    'Incrível! Sinta-se à vontade para adicionar mais se quiser!',
  'create_with_ai.strength.tooltip':
    'Prompts detalhados levam a insights mais precisos e relevantes—dê-nos mais contexto para obter os melhores resultados!',
  'file_import.import_file_title': '(Opcional) Faça upload dos seus documentos',
  'file_import.import_file_info':
    'Faça upload de quaisquer documentos para incluir ou referenciar em sua apresentação.',
  'file_import.use_file_format':
    'Não é possível fazer upload do arquivo {{fileName}}. Use um arquivo nos seguintes formatos: docx, json, md, pdf, pptx, txt',
  'file_import.maximum_files': 'Você pode fazer upload de no máximo 5 arquivos',
  'file_import.maximum': 'Você atingiu o limite máximo de upload de arquivos!',
  'file_import.maximum_info':
    'Por favor, exclua um para fazer upload de outro.',
  'file_import.drag_and_drop': 'Arraste seus arquivos ou procure',
  'file_import.size_error':
    'O arquivo {{fileName}} é muito grande. Tente um menor.',
  'file_import.already_uploaded':
    'O arquivo {{fileName}} já foi carregado. Tente outro.',
  'file_import.file_state.ready': 'Pronto',
  'file_import.file_state.error': 'Erro',
  'file_import.file_state.processing': 'Processando',
  'file_import.file_state.ready_info': 'Arquivo carregado com sucesso.',
  'file_import.file_state.error_info':
    'Desculpe, ocorreu um erro inesperado. Por favor, tente fazer upload de outro arquivo.',
  'file_import.file_state.processing_info':
    'O arquivo está sendo processado, por favor aguarde.',
  'subscriptions.change_payment_method': 'Alterar Método de Pagamento',
  'subscriptions.update_billing_info': 'Atualizar Informações de Faturamento',
  'subscriptions.invoices': 'Faturas',
  'subscriptions.cancel_subscription': 'Cancelar Assinatura',
  'subscriptions.renew_subscription': 'Renovar Assinatura',
  'subscriptions.cancel_promo_modal.title': '75% DE DESCONTO POR 3 MESES',
  'subscriptions.cancel_promo_modal.subtitle':
    'Notamos que você está prestes a cancelar sua assinatura',
  'subscriptions.cancel_promo_modal.description':
    'Como um usuário valioso, gostaríamos de oferecer a você um exclusivo <strong>{{discount}}</strong> em seus próximos {{period}} de assinatura se você optar por ficar com o Decktopus.',
  'subscriptions.cancel_promo_modal.cancel': 'Cancelar minha assinatura',
  'subscriptions.cancel_promo_modal.apply': 'Aplicar Desconto',
  'subscriptions.cancel_promo_modal.success':
    'Seu desconto foi aplicado com sucesso',
  'subscriptions.cancel_promo_modal.discount_monthly': '75% de desconto',
  'subscriptions.cancel_promo_modal.discount_annually': '25% de desconto',
  'subscriptions.cancel_promo_modal.period_monthly': '3 meses',
  'subscriptions.cancel_promo_modal.period_annually': 'ano',
  'subscriptions.cancel_reason_dialog.title':
    'O que poderíamos ter feito de diferente para melhorar sua experiência?',
  'subscriptions.cancel_reason_dialog.subtitle': 'Mínimo de 50 caracteres',
  'subscriptions.cancel_reason_dialog.submit': 'Enviar feedback',
  'subscriptions.cancel_reason_dialog.input_placeholder':
    'Digite sua experiência aqui.',
  'subscriptions.cancel_reason_dialog.input_label': 'Feedback',
  'subscriptions.cancel_reason_dialog.footer_text':
    'Seu feedback é incrivelmente valioso para nós. Ele nos ajuda a melhorar e criar uma experiência melhor para você e outros no futuro. Mesmo os menores insights podem fazer uma grande diferença. Obrigado por compartilhar!',
  'edit.properties.chart_props.show_background': 'Mostrar Plano de Fundo',
  'share_modal.compressed_export_message':
    'A compressão reduz o tamanho do arquivo combinando texto, imagens e outros componentes em imagens otimizadas para cada slide, facilitando o armazenamento e o compartilhamento.',
  'share_modal.include_slide_notes': 'Incluir notas do slide',
  'share_modal.include_slide_notes_info_tooltip':
    'As notas serão adicionadas sob a miniatura da página.',
  'ai_loading.text1':
    'Você sabia que as pessoas lembram apenas 10% do que ouvem após três dias, mas lembram 65% se uma imagem for adicionada?',
  'ai_loading.text2':
    'Em média, as pessoas tomam uma decisão sobre uma apresentação nos primeiros 90 segundos.',
  'ai_loading.text3':
    'Recursos visuais em uma apresentação podem melhorar o aprendizado em até 400%.',
  'ai_loading.text4':
    'A apresentação mais longa já realizada durou 124 horas e foi feita por Arvind Mishra na Índia em 2018.',
  'ai_loading.text5':
    'Usar cores de alto contraste em seus slides pode aumentar a legibilidade em até 78%.',
  'ai_loading.text6':
    'Você sabia? 55% da comunicação é linguagem corporal, 38% é tom de voz e apenas 7% são as palavras realmente faladas.',
  'ai_loading.text7':
    'O tempo médio de atenção de uma pessoa é de apenas 8 segundos – faça seus slides contarem!',
  'ai_loading.text8':
    'Dica de Comunicação: Use storytelling para tornar suas apresentações mais memoráveis.',
  'ai_loading.text9':
    'Apresentações com slides mais simples tendem a ser mais impactantes.',
  'ai_loading.text10':
    'Incorporar histórias em sua apresentação pode torná-la 22 vezes mais memorável.',
  'ai_loading.text11':
    'Manter contato visual com sua audiência pode aumentar o engajamento e a confiança.',
  'ai_loading.text12':
    'As pessoas lembram apenas 10% do que ouvem após três dias, mas essa taxa aumenta para 65% se uma imagem for adicionada.',
  'ai_loading.text13':
    'As pessoas decidem sobre uma apresentação nos primeiros 90 segundos.',
  'ai_loading.text14':
    'Recursos visuais podem melhorar o aprendizado em até 400%!',
  'ai_loading.text15':
    'A apresentação mais longa durou 124 horas por Arvind Mishra na Índia, 2018.',
  'ai_loading.text16':
    'Cores de alto contraste nos slides podem aumentar a legibilidade em 78%.',
  'ai_loading.text17':
    '55% da comunicação é linguagem corporal, 38% tom e 7% palavras.',
  'ai_loading.text18':
    'O tempo médio de atenção é de 8 segundos – faça seus slides contarem!',
  'ai_loading.text19':
    'Dica: Use storytelling para tornar as apresentações memoráveis.',
  'ai_loading.text20':
    'Slides mais simples frequentemente têm um impacto maior.',
  'ai_loading.text21':
    'Histórias tornam as apresentações 22 vezes mais memoráveis.',
  'ai_loading.text22':
    'O contato visual aumenta o engajamento e a confiança da audiência.',
}
