import React, { useCallback, useMemo } from 'react'
import { useLanguage, useNotification } from 'src/hooks'
import { ILanguageChanger } from './types'
import { languageChangerStyles } from './styles'
import { Dropdown, IDropdownItem, IDropdownValue } from 'src/lib'
import { DateTimeServices } from 'src/services'
import {
  AVAILABLE_LANGUAGES,
  DecktopusLanguages,
  LANGUAGE_NAMES,
} from 'src/i18n/locales/consts'

export const LanguageChanger: React.FC<ILanguageChanger> = React.memo(
  ({ className, dataAttr }) => {
    const { t, lang, changeLanguage, fallbackLng } = useLanguage()
    const { error } = useNotification()

    const dropdownItems = useMemo(() => {
      const languages = AVAILABLE_LANGUAGES
      return languages
        .map((language) => {
          const lang = language as DecktopusLanguages
          return {
            name: LANGUAGE_NAMES[lang],
            value: language,
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
    }, [lang])

    const onChangeHandler = useCallback((value?: IDropdownItem) => {
      const langValue = value?.value as IDropdownValue as string
      if (!langValue) return

      try {
        changeLanguage(langValue)
        DateTimeServices.moment.locale(langValue)
        localStorage.setItem('currentLanguage', langValue)
      } catch {
        error(t('common.informative.error_changing_language'))
        changeLanguage(fallbackLng)
        DateTimeServices.moment.locale(fallbackLng)
        localStorage.setItem('currentLanguage', fallbackLng)
      }
    }, [])

    const languagePlaceholder = useMemo(
      () => LANGUAGE_NAMES[lang as DecktopusLanguages],
      [lang],
    )

    return (
      <div css={languageChangerStyles} className={className} {...dataAttr}>
        <Dropdown
          placeholder={languagePlaceholder}
          items={dropdownItems}
          selected={lang}
          onChange={onChangeHandler}
        />
      </div>
    )
  },
)

LanguageChanger.displayName = 'LanguageChanger'
