import React, { useCallback, useEffect, useState } from 'react'
import { titleStyles, areaStyles } from './styles'
import { CreateCard, CREATECARD_TYPE } from 'src/lib/create-card'
import { useFlags, useLanguage } from 'src/hooks'

import scratchImg from 'src/assets/images/start-from-scratch.svg'
import templateImg from 'src/assets/images/start-with-a-template.svg'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'src/lib'
import { CreateWithAiForm } from 'src/components/create-with-ai-form'
import { useDispatch } from 'react-redux'
import {
  AI_FLOW_STEP,
  recoverAIFlow,
  setAiFlowStep,
  setPrompt,
} from 'src/store'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import { decodeLandingPrompt } from 'src/services/stringServices'

export const DashboardCreate: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const navigate = useNavigate()
  const { getAiFlowAudience } = useAiFlowApi()
  const flags = useFlags()

  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()

  const onScratchClick = useCallback(() => {
    navigate('/init/scratch', { replace: true })
  }, [])

  const onTemplateClick = useCallback(() => {
    navigate('/init/template', { replace: true })
  }, [])

  const onCreateWithAi = useCallback(() => {
    dispatch(
      setAiFlowStep(
        flags.FE_29_AI_FILE_FLOW ? AI_FLOW_STEP.PROMPT : AI_FLOW_STEP.MODAL,
      ),
    )
    setOpenModal(!openModal)
  }, [openModal])

  const [landingPrompt, setLandingProp] = useState<string | undefined>()

  useEffect(() => {
    const landPrompt = localStorage.getItem('landingPrompt')

    if (landPrompt) {
      setLandingProp(landPrompt)
    }
    const savedAIFlow = localStorage.getItem('saved-ai-flow')
    if (savedAIFlow) {
      dispatch(recoverAIFlow(JSON.parse(savedAIFlow)))
      localStorage.removeItem('saved-ai-flow')
      setOpenModal(true)
    }
  }, [])

  useEffect(() => {
    const localTryParam = localStorage.getItem('try')

    if (localTryParam) {
      if (localTryParam === 'ai') {
        dispatch(setAiFlowStep(AI_FLOW_STEP.PROMPT))
        setOpenModal(true)
      }
      if (localTryParam === 'import') {
        dispatch(
          setAiFlowStep(
            flags.FE_29_AI_FILE_FLOW
              ? AI_FLOW_STEP.PROMPT
              : AI_FLOW_STEP.IMPORT,
          ),
        )
        setOpenModal(true)
      }

      localStorage.removeItem('try')
    }
  }, [])

  useEffect(() => {
    if (landingPrompt) {
      nextButton()
    }
  }, [landingPrompt])

  const nextButton = useCallback(async () => {
    if (!landingPrompt) {
      return
    }

    try {
      const prompt = decodeLandingPrompt(landingPrompt)
      setOpenModal(true)
      dispatch(
        setAiFlowStep(
          flags.FE_29_AI_FILE_FLOW
            ? AI_FLOW_STEP.CATEGORY
            : AI_FLOW_STEP.AUDIENCE,
        ),
      )
      dispatch(setPrompt(prompt))
      await getAiFlowAudience({
        prompt,
        category: null,
      })
    } catch {
      console.error('Dashboard create button click error: ')
    }
  }, [landingPrompt])

  const onCloseHandler = useCallback(() => {
    setOpenModal(!openModal)
  }, [openModal])

  return (
    <div>
      <div css={titleStyles}>{t('dashboard.create.create_presentation')}</div>
      <div css={areaStyles}>
        <CreateCard
          img={scratchImg}
          text={t('dashboard.create.start_from_scratch')}
          onClick={onScratchClick}
        />
        <CreateCard
          img={templateImg}
          text={t('dashboard.create.create_with_a_template')}
          onClick={onTemplateClick}
        />
        <CreateCard
          type={CREATECARD_TYPE.GRADIENT}
          text={t('create_with_ai.create_with_ai')}
          onClick={onCreateWithAi}
        />
        {openModal && (
          <Modal
            isOpen={openModal}
            onClose={onCloseHandler}
            preventClickOutside
          >
            <CreateWithAiForm onCloseClick={onCloseHandler} />
          </Modal>
        )}
      </div>
    </div>
  )
})
