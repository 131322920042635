import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiPromptStyles = (theme: ITheme) => css`
  position: static;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.small}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    position: relative;
    overflow-y: scroll;
  }
`

export const viewStyles = (page1: boolean) => (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.xsmall}px;

  height: auto;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    height: ${page1
      ? `${theme.dimensions.aiModalHeight.prompt}`
      : `${theme.dimensions.aiModalHeight.DEFAULT}`}px;
  }

  @media (min-width: ${theme.breakpoints.laptop}px) {
    gap: ${theme.gap.small}px;
    height: ${page1
      ? `${theme.dimensions.aiModalHeight.medium}`
      : `${theme.dimensions.aiModalHeight.large}`}px;
  }
`

export const inputStyles = (isError?: boolean) => (theme: ITheme) => css`
  border: 2px solid transparent;
  border-radius: ${theme.borderRadius[6]};
  ${!isError
    ? `background: ${theme.gradient.PURPLE_BORDER};`
    : `background: ${theme.gradient.RED_BORDER};`}
  background-origin: border-box;
  background-clip: padding-box, border-box;
  ${isError && 'margin-bottom: 20px;'}
`

export const buttonStyles = (theme: ITheme) => css`
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  width: 100%;
  padding: ${theme.gap.small}px ${theme.gap['3xlarge']}px ${theme.gap.medium}px
    0;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    position: relative;
    padding: 0;
  }
  & button {
    font-size: ${theme.fontSize.base};
  }
`

export const seeExampleButtonStyles = (theme: ITheme) => css`
  color: ${theme.colors.primary.DEFAULT};
  font-size: ${theme.fontSize.small};
  cursor: pointer;
  @media (min-width: ${theme.breakpoints.laptop}px) {
    font-size: ${theme.fontSize.base};
  }
`

export const aiSuggestionStyles = (selected: boolean) => (theme: ITheme) => css`
  width: 100%;
  min-height: 36px;
  cursor: pointer;

  padding: ${theme.gap.small}px;
  flex: 1;
  min-height: max-content;
  display: inline-flex;

  ${selected
    ? `border: 1px solid ${theme.colors.primary.DEFAULT};`
    : `border: 1px solid ${theme.colors.outline[2]};`}
  border-radius: ${theme.borderRadius[4]};
  padding: ${theme.gap.xsmall}px ${theme.gap.small}px;
  box-shadow: ${theme.boxShadow[1]};
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  @media (min-width: ${theme.breakpoints.laptop}px) {
    padding: ${theme.gap.small}px;
    flex: 1;
    min-height: max-content;
    display: inline-flex;
    font-size: ${theme.fontSize.base};
  }

  & div {
    height: max-content;
    flex-grow: 1;
  }
`

export const exampleAreaStyles = (theme: ITheme) => css`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.small}px;
  overflow: auto;
  margin-top: ${theme.gap.medium}px;
  height: auto;
`
