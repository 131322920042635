import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
export const containerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const rightContainerStyle = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  gap: ${gap['3xsmall']}px;
  .tooltip {
    height: 16px;
  }
`

export const strengthBarStyle = ({ colors, borderRadius }: ITheme) => css`
  height: 12px;
  border-radius: ${borderRadius[12]};
  background-color: ${colors.outline.DEFAULT};
  overflow: hidden;
  width: 100px;
`

export const strengthFillStyle =
  (strength: number) =>
  ({ colors }: ITheme) => css`
    width: ${strength}%;
    height: 100%;
    background-color: ${strength <= 33
      ? colors.error.DEFAULT
      : strength <= 66
      ? colors.warning.DEFAULT
      : colors.success.DEFAULT};
    transition:
      width 0.3s ease,
      background-color 0.3s ease;
  `

export const messageStyle =
  (strength: number) =>
  ({ colors, fontSize }: ITheme) => css`
    font-size: ${fontSize.base};
    color: ${strength <= 33
      ? colors.error.DEFAULT
      : strength <= 66
      ? colors.warning.DEFAULT
      : colors.success.DEFAULT};
  `
