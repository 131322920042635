import React, { useCallback, useEffect, useRef, useState } from 'react'

import { IAiOutline } from './types'
import { aiOutlineStyles, outlineStyles } from './styles'
import { buttonStyles, viewStyles } from '../ai-prompt/styles'
import { Steps } from 'src/lib/steps'
import { BUTTON_THEME, BUTTON_TYPE, Button, Icon, icons } from 'src/lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowStep,
  updateSelectedOutline,
} from 'src/store'
import { useClickOutside, useFlags, useLanguage } from 'src/hooks'
import { ListEditor } from '../../list-editor'
import { clearHtmlTags } from 'src/services/stringServices'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import { pdfNameStyles, stepStyles } from '../styles'
import { AiDeckCategories } from 'src/types/api/enums'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'

export const AiOutline: React.FC<IAiOutline> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const flags = useFlags()

    const outlineArea = useRef(null)
    const posthogCapture = usePostHogCapture()

    const { getAiFlowTheme } = useAiFlowApi()
    const {
      selectedOutline,
      pdfName,
      pdfImport,
      promptId,
      selectedCategory,
      aiFlowId,
    } = useSelector(({ aiFlow }: RootState) => ({
      selectedCategory: aiFlow.selectedCategory,
      selectedOutline: aiFlow.selectedOutline,
      pdfName: aiFlow.pdfFileName,
      pdfImport: aiFlow.pdfImport,
      promptId: aiFlow.selectedPromptId,
      aiFlowId: aiFlow.aiFlowId,
    }))

    useEffect(() => {
      aiFlowId &&
        posthogCapture(POSTHOG_EVENTS.AI_FLOW_OUTLINE_VIEW, {
          ai_flow_id: aiFlowId,
        })
    }, [aiFlowId])

    const [focus, setFocus] = useState(false)

    const outsideClick = useCallback(() => {
      setFocus(false)
    }, [focus])

    useClickOutside(outlineArea, outsideClick)

    useEffect(() => {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !focus) {
          event.preventDefault()
          if (selectedOutline.length > 2) {
            nextButton()
          }
        }
      }
      document.addEventListener('keydown', keyDownHandler)
      return () => {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }, [focus, selectedOutline])

    const [loadingState, setLoadingState] = useState(false)

    const nextButton = async () => {
      setLoadingState(true)
      const cleanData = clearHtmlTags(selectedOutline)
      dispatch(updateSelectedOutline(cleanData))
      const apiResult = await getAiFlowTheme({
        outline: cleanData,
        deckPromptId: promptId,
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.THEME))
      }
      setLoadingState(false)
    }

    const backButton = () => {
      dispatch(
        setAiFlowStep(
          flags.FE_29_AI_FILE_FLOW
            ? selectedCategory === AiDeckCategories.OTHER
              ? AI_FLOW_STEP.AIM
              : AI_FLOW_STEP.IMPORT
            : AI_FLOW_STEP.AIM,
        ),
      )
      dispatch(
        updateSelectedOutline(
          selectedOutline.filter((line) => line.trim() !== ''),
        ),
      )
    }

    const focusActive = useCallback(() => {
      setFocus(true)
    }, [focus])

    const steps =
      flags.FE_29_AI_FILE_FLOW && selectedCategory !== AiDeckCategories.OTHER
        ? 3
        : 5
    const currentStep =
      flags.FE_29_AI_FILE_FLOW && selectedCategory !== AiDeckCategories.OTHER
        ? 2
        : 4
    return (
      <div className={className} {...dataAttr}>
        <div css={viewStyles(false)}>
          {!pdfName && (
            <Steps steps={steps} current={currentStep} css={stepStyles} />
          )}
          {pdfImport && (
            <div css={pdfNameStyles}>
              <Icon icon={icons.pdf}></Icon>
              {pdfName}
            </div>
          )}
          <div css={aiOutlineStyles} ref={outlineArea} onClick={focusActive}>
            <ListEditor css={outlineStyles} />
          </div>
        </div>
        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            isLink
            onClick={backButton}
          />
          <Button
            text={t('common.actions.next')}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={selectedOutline.length < 3}
            isLoading={loadingState}
          />
        </div>
      </div>
    )
  },
)

AiOutline.displayName = 'AiOutline'
