import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  spanWrapperStyles,
  deckNameStyles,
  bottomSpaceWrapper,
  exportTypeDropdownWrapperStyles,
} from '../../styles'
import { useDecksApi, useInterval, useLanguage } from 'src/hooks'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  DROPDOWN_THEME,
  Dropdown,
  IDropdownItem,
  Icon,
  TOOLTIP_PLACE,
  Tooltip,
  icons,
} from 'src/lib'
import { COLOR, colors } from 'src/theme'
import {
  deckNameFontWeight,
  exportPageStyles,
  exportTitleStyles,
  iconStyles,
  latestExportStyles,
  spanAndTextStyles,
  spanStyles,
  tabStyles,
  underlineStyles,
  wrapperStyles,
  overflowAreaStyles,
  exportHistoryWrapperStyles,
  exportCompressedInfoStyles,
  checkboxWrapperStyles,
  optionLabelStyles,
  iconInfoWrapperStyles,
} from './styles'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { DeckExportStates, DeckExportTypes } from 'src/types/api/enums'
import { IExport } from './types'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { ACTION_CLICKS } from 'src/plugins/google/consts'

export enum IExportDropdownValue {
  PDF = 'pdf',
  PDFCOMPRESSED = 'pdf_compressed',
  PPT = 'ppt',
  PPTCOMPRESSED = 'ppt_compressed',
}

export const Export: React.FC<IExport> = React.memo(({ deckId, deckName }) => {
  const { t } = useLanguage()

  const { getExportedData, orgId, userPlan } = useSelector(
    ({ decks, workspace, user }: RootState) => ({
      getExportedData: decks.exportedFileData,
      orgId: workspace.id,
      userPlan: user.data?.activeUserPlan?.plan.name,
    }),
  )

  const [selectedExportType, setSelectedExportType] = useState(
    IExportDropdownValue.PDFCOMPRESSED,
  )
  const [exportSlideNotes, setExportSlideNotes] = useState(false)

  useEffect(() => {
    exportedData()
  }, [selectedExportType, deckId])

  const exportedData = useCallback(async () => {
    if (deckId)
      await getDownloadedExportList(
        {
          organizationId: orgId,
          exportType:
            selectedExportType === IExportDropdownValue.PDF ||
            selectedExportType === IExportDropdownValue.PDFCOMPRESSED
              ? DeckExportTypes.PDF
              : DeckExportTypes.PPTX,
        },
        deckId,
      )
  }, [deckId, orgId, selectedExportType])

  useInterval(() => {
    getStatus().length > 0 && exportedData()
  }, 8000)

  const getStatus = () => {
    return getExportedData.filter(
      (line) => line.state == DeckExportStates.PROCESSING,
    )
  }

  const { getExport, getDownloadedExportList } = useDecksApi()

  const onExportClickHandler = async ({
    selectedExportType,
    exportSlideNotes,
    isCallback,
  }: {
    selectedExportType: IExportDropdownValue
    exportSlideNotes: boolean
    isCallback: boolean
  }) => {
    if (isCallback) {
      setIsUpgradeModalOpen(false)
      return
    }
    if (!userPlan && !orgId && !isCallback) {
      setIsUpgradeModalOpen(true)
      return
    }
    deckId &&
      (await getExport({ selectedExportType, exportSlideNotes, deckId }))
    exportedData()
  }

  const onDownloadClickHandler = useCallback((item: any) => {
    window.open(item.fileUrl, '_blank')

    const downloadedItemIds = localStorage.getItem('exportedFileIds')

    if (!downloadedItemIds?.includes(item.id)) {
      if (downloadedItemIds) {
        const newValues = JSON.parse(downloadedItemIds).concat([item.id])
        localStorage.setItem('exportedFileIds', JSON.stringify(newValues))
      } else localStorage.setItem('exportedFileIds', JSON.stringify([item.id]))
    }
    exportedData()
  }, [])

  const exportedDataTemplate = useMemo(() => {
    return getExportedData.map((exportItem, index) => (
      <div css={exportPageStyles} key={index}>
        <>
          <div css={spanAndTextStyles}>
            <Icon
              icon={icons.folder}
              color={COLOR.primary_100}
              size={24}
              css={iconStyles}
            />
            <span css={spanStyles}>{exportItem.fileName}</span>
          </div>
          {exportItem.state == '0' && (
            <div css={underlineStyles}>
              {t('common.informative.processing')}
            </div>
          )}
          {exportItem.fileUrl && exportItem.state == '1' && (
            <Button
              css={underlineStyles}
              onClick={() => onDownloadClickHandler(exportItem)}
              text={t('common.actions.download')}
              type={BUTTON_TYPE.GHOST}
              isLink
            />
          )}
          {exportItem.state == '2' && (
            <div css={underlineStyles}>{t('common.informative.fail')}</div>
          )}
        </>
      </div>
    ))
  }, [getExportedData])

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)

  const onExportTypeChangeHandler = useCallback((value?: IDropdownItem) => {
    setSelectedExportType(value!.value as IExportDropdownValue)
  }, [])

  return (
    <div css={wrapperStyles}>
      <div css={bottomSpaceWrapper}>
        <div css={deckNameStyles}>
          <span css={spanWrapperStyles}>
            {t('common.actions.export')}:
            <span css={deckNameFontWeight}>{deckName}</span>
          </span>
        </div>
      </div>
      <div css={overflowAreaStyles}>
        <div css={exportTypeDropdownWrapperStyles}>
          <Dropdown
            selected={selectedExportType}
            onChange={onExportTypeChangeHandler}
            items={[
              {
                name: t('common.files.pdf_standard'),
                value: IExportDropdownValue.PDF as string,
              },
              {
                name: t('common.files.pdf_compressed'),
                value: IExportDropdownValue.PDFCOMPRESSED as string,
              },
              {
                name: t('common.files.ppt_standard'),
                value: IExportDropdownValue.PPT as string,
              },
              {
                name: t('common.files.ppt_compressed'),
                value: IExportDropdownValue.PPTCOMPRESSED as string,
              },
            ]}
            css={tabStyles}
            theme={DROPDOWN_THEME.DEFAULT}
          />
          <Button
            text={t('common.actions.export')}
            disabled={getStatus().length > 0}
            onClick={() =>
              onExportClickHandler({
                selectedExportType,
                exportSlideNotes,
                isCallback: false,
              })
            }
            type={BUTTON_TYPE.DEFAULT}
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.PRIMARY}
          />
        </div>
        <div css={checkboxWrapperStyles}>
          <input
            id={'include-slide-notes'}
            type="checkbox"
            onChange={(e) => setExportSlideNotes(e.target.checked)}
            checked={exportSlideNotes}
          />
          <label css={optionLabelStyles} htmlFor={'include-slide-notes'}>
            {t('share_modal.include_slide_notes')}
          </label>
          <Tooltip
            text={t('share_modal.include_slide_notes_info_tooltip')}
            place={TOOLTIP_PLACE.TOP_LEFT}
          >
            <div css={iconInfoWrapperStyles}>
              <Icon
                className="icon"
                icon={icons.info}
                size={18}
                color={colors.text[2]}
              />
            </div>
          </Tooltip>
        </div>
        <div css={exportTitleStyles}>
          <UpgradeModalManager
            isOpen={isUpgradeModalOpen}
            callback={() =>
              onExportClickHandler({
                selectedExportType,
                exportSlideNotes,
                isCallback: true,
              })
            }
            context={ACTION_CLICKS.EXPORT_CLICK}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        </div>
        {(selectedExportType === IExportDropdownValue.PDFCOMPRESSED ||
          selectedExportType === IExportDropdownValue.PPTCOMPRESSED) && (
          <div css={exportCompressedInfoStyles}>
            <div>
              <Icon icon={icons.info} size={16} color={colors.black.DEFAULT} />
            </div>
            <span>{t('share_modal.compressed_export_message')}</span>
          </div>
        )}
        <div css={exportHistoryWrapperStyles}>
          <div css={latestExportStyles(getExportedData.length > 0)}>
            {t('common.history')}
          </div>
          {exportedDataTemplate}
        </div>
      </div>
    </div>
  )
})
