import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = ({ fontSize, spacing }: ITheme) => css`
  max-width: 541px;
  width: 100% !important;
  .title {
    font-size: ${fontSize.base};
    margin-bottom: ${spacing['3xsmall']};
  }
  .text {
    font-size: ${fontSize.xsmall};
  }
`
