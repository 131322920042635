import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useAuthProvider } from 'src/provider/authProvider'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { useFlags, useUserApi } from 'src/hooks'
import { useSelector, useDispatch } from 'react-redux'
import {
  RootState,
  setAdminToken,
  setWorkspaceClearState,
  setIsOwner,
  setPaymentFailed,
  setWorkspaceId,
} from 'src/store'
import RefreshOverlay from './components/RefreshOverlay'
import useIdleTimer from 'src/hooks/useIdleTimer'
import { HelmetProvider } from 'src/provider'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { usePostHog } from 'posthog-js/react'
import Gleap from 'gleap'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import { decodeLandingPrompt } from 'src/services/stringServices'

export const ProtectedPageLayout: React.FC = React.memo(() => {
  const { token } = useAuthProvider()
  const [isWorkspaceReady, setIsWorkspaceReady] = useState(false)
  const [searchParams] = useSearchParams()
  const ldClient = useLDClient()
  const posthog = usePostHog()
  const posthogCapture = usePostHogCapture()
  const flags = useFlags()
  const { getUser, getOrganizations } = useUserApi()
  const [isOutletReadyToRender, setIsOutletReadyToRender] = useState(false)

  const { workspaceId, user } = useSelector(
    ({ workspace, user }: RootState) => ({
      workspaceId: workspace.id,
      user: user.data,
    }),
  )
  const { isIdle, isExpired } = useIdleTimer()

  useEffect(() => {
    const handleFetching = async () => {
      await getUser()
      const organizations = await getOrganizations()
      if (!organizations.length) {
        dispatch(setWorkspaceClearState())
      }
      setIsOutletReadyToRender(true)
    }

    if (token && flags.FE_402_ORGANIZATIONS_REFACTOR) {
      handleFetching()
    }
  }, [token, flags.FE_402_ORGANIZATIONS_REFACTOR])

  useEffect(() => {
    // In case any admin logged in as user and refreshed the page
    if (!flags.FE_419_ADMIN_LOGIN_AS_USER) {
      return
    }
    const lsAdminToken = localStorage.getItem('adminToken')
    if (lsAdminToken) {
      dispatch(setAdminToken({ adminToken: lsAdminToken! }))
    }
  }, [flags.FE_419_ADMIN_LOGIN_AS_USER])

  useLayoutEffect(() => {
    const handleFetching = async () => {
      await workspacePreparation()
    }

    if (token && flags.FE_402_ORGANIZATIONS_REFACTOR === false) {
      handleFetching()
    }
  }, [token, workspaceId, flags.FE_402_ORGANIZATIONS_REFACTOR])

  useEffect(() => {
    const isDecktopus = user?.email.includes('@decktopus.com')

    let ldKey = 'USER_ANNMYS'
    let ldName = 'User Anonymous'
    let ldEmail = 'user_annmys@decktopus.com'

    if (isDecktopus) {
      ldKey = 'USER_DECKTOPUS'
      ldName = 'User Decktopus'
      ldEmail = 'user@decktopus.com'
    }

    ldClient
      ?.identify({
        kind: 'user',
        key: ldKey,
        name: ldName,
        email: ldEmail,
      })
      .catch((error) => {
        console.error('Launchdarkly client identify error:', error.message)
      })
  }, [user])

  const dispatch = useDispatch()

  // TODO: remove this function with removal of FE_402_ORGANIZATIONS_REFACTOR flag, as this logic relocated to WorkspaceSelector component.
  // check that localstorage workspace id is exist in userOrganizations and update or remove workspace accordingly
  const workspacePreparation = useCallback(async () => {
    const user = await getUser()
    const organizations = await getOrganizations()

    const ownedOrgId = organizations.find(
      (organization) => organization.organization.ownerUserId === user.user?.id,
    )?.organization.id

    const isOrganizationActive = organizations.find(
      (organization) =>
        organization.organization.id === (workspaceId || ownedOrgId),
    )?.organization.isActive

    const findOrgWithWorkspaceId = organizations.find(
      (organization) => organization.organization.id === workspaceId,
    )?.organization.id

    if (workspaceId && !isOrganizationActive) {
      dispatch(setPaymentFailed(true))
    } else dispatch(setPaymentFailed(false))

    // return undefined when no organization id matches with workspaceId and when there is no organization which is owned by user
    if (findOrgWithWorkspaceId) {
      dispatch(setWorkspaceId(findOrgWithWorkspaceId))
      dispatch(setIsOwner(findOrgWithWorkspaceId === ownedOrgId))
    } else if (ownedOrgId) {
      dispatch(setWorkspaceId(ownedOrgId))
      dispatch(setIsOwner(true))
    } else {
      dispatch(setWorkspaceId())
      dispatch(setIsOwner(false))
    }
    setIsWorkspaceReady(true)
    return
  }, [workspaceId])

  const tryParam = searchParams.get('try')
  const aiParam = searchParams.get('ai')

  if (tryParam) {
    localStorage.setItem('try', tryParam)
  }

  // Check if the user is authenticated
  if (!token || isExpired) {
    if (isExpired) {
      try {
        if (posthog && posthog.__loaded) {
          posthog.reset()
        }
      } catch {
        console.error('posthog reset ProtectedPageLayout error: ')
      }
    }

    try {
      if (Gleap) {
        Gleap.clearIdentity()
      }
    } catch {
      console.error('Gleap clearIdentity ProtectedPageLayout error: ')
    }
    // If not authenticated, redirect to the login page
    if (aiParam) {
      posthogCapture(POSTHOG_EVENTS.AI_FLOW_PROMPT_LANDING, {
        input_value: decodeLandingPrompt(aiParam),
      })
    }

    return <Navigate to={tryParam || aiParam ? '/sign-up' : '/login'} />
  }

  if (tryParam) {
    return <Navigate to={'/dashboard'} />
  }

  return (
    <>
      <HelmetProvider isExportMode={window.location.href.includes('/export')} />
      {isIdle && <RefreshOverlay />}
      {((flags.FE_402_ORGANIZATIONS_REFACTOR && isOutletReadyToRender) ||
        (!flags.FE_402_ORGANIZATIONS_REFACTOR && isWorkspaceReady)) && (
        <Outlet />
      )}
    </>
  )
})

export default ProtectedPageLayout
