import { useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { RootState, setOutlineParams } from 'src/store'
import {
  setOutline,
  updateAimSuggestion,
  updateAudienceSuggestion,
  setPromptId,
  setTemplates,
} from 'src/store'
import {
  deleteFileBody,
  postCheckDeckFileStatusBody,
  postDeckFileUploadUrlsBody,
  postSaveAudienceBody,
  postSavePDFAimBody,
  postSavePDFContentBody,
  postSaveTopicAimBody,
  postSaveTopicOutlineBody,
  postSaveTopicPromptBody,
} from 'src/types/api/requestObjects'
import {
  defaultResponseSchema,
  getDeckFileUploadUrlsResponse,
  postCheckDeckFileStatusResponse,
  postGetAIThemesResponse,
  postGetAimResponse,
  postGetAudienceResponse,
  postGetOutlineResponse,
} from 'src/types/api/responseObjects'

interface IUseAiFlowApiReturn {
  getAiFlowFileUploadUrls: (
    params: postDeckFileUploadUrlsBody,
  ) => Promise<getDeckFileUploadUrlsResponse['data'] | null>
  checkAiFlowFileStatus: (
    params: postCheckDeckFileStatusBody,
  ) => Promise<postCheckDeckFileStatusResponse['data']['deckFiles'] | null>
  deleteAiFlowFiles: (params: deleteFileBody) => Promise<boolean>
  getPdfImportAudience: (pdfContent: postSavePDFContentBody) => Promise<boolean>
  getAiFlowAudience: (promptValue: postSaveTopicPromptBody) => Promise<boolean>
  getAiFlowAim: (params: postSaveAudienceBody) => Promise<boolean>
  getAiFlowOutline: (params: postSaveTopicAimBody) => Promise<boolean>
  getAiFlowTheme: (params: postSaveTopicOutlineBody) => Promise<boolean>
  getPdfImportTheme: (params: postSavePDFAimBody) => Promise<boolean>
  isLoading: boolean
}

export const useAiFlowApi = (): IUseAiFlowApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { aiFlowId, selectedOutlineParams } = useSelector(
    ({ aiFlow }: RootState) => ({
      aiFlowId: aiFlow.aiFlowId,
      selectedOutlineParams: aiFlow.selectedOutlineParams,
    }),
  )

  const getPdfImportAudience = async (pdfContent: postSavePDFContentBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAudienceResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/pdf/content`,
          data: {
            pdfContent: pdfContent.pdfContent,
          },
        })

      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return false
      }
      dispatch(setPromptId(res.data.data.deckPromptId))
      if (res.data.data.audiences) {
        dispatch(updateAudienceSuggestion(res.data.data.audiences))
      }
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowAudience = async (promptValue: postSaveTopicPromptBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAudienceResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/topic/prompt`,
          data: promptValue,
        })

      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      const landPrompt = localStorage.getItem('landingPrompt')
      if (currentAiFlowId !== aiFlowId && !landPrompt) {
        return false
      }
      dispatch(setPromptId(res.data.data.deckPromptId))
      if (res.data.data.audiences) {
        dispatch(updateAudienceSuggestion(res.data.data.audiences))
      }
      localStorage.removeItem('landingPrompt')
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowAim = async (params: postSaveAudienceBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAimResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/audience`,
          data: {
            deckPromptId: params.deckPromptId,
            audience: params.audience,
          },
        })

      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return false
      }
      dispatch(updateAimSuggestion(res.data.data.aims))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowOutline = async (params: postSaveTopicAimBody) => {
    setIsLoading(true)
    try {
      if (selectedOutlineParams && isEqual(selectedOutlineParams, params)) {
        return true
      }
      const res: RequestResponse<postGetOutlineResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/topic/aim`,
          data: {
            ...params,
          },
        })

      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return false
      }
      dispatch(setOutlineParams(params))
      dispatch(setOutline(res.data.data.sections))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getPdfImportTheme = async (params: postSavePDFAimBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAIThemesResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/pdf/aim`,
          data: {
            deckPromptId: params.deckPromptId,
            aim: params.aim,
          },
        })
      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return false
      }
      dispatch(setTemplates(res.data))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowTheme = async (params: postSaveTopicOutlineBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postGetAIThemesResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/topic/outline`,
          data: {
            deckPromptId: params.deckPromptId,
            outline: params.outline,
          },
        })
      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return false
      }
      dispatch(setTemplates(res.data))
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getAiFlowFileUploadUrls = async (
    params: postDeckFileUploadUrlsBody,
  ) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDeckFileUploadUrlsResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.DECKS}/file/upload`,
          data: {
            ...params,
          },
        })

      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return null
      }

      return res.data.data
    } catch {
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const checkAiFlowFileStatus = async (params: postCheckDeckFileStatusBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postCheckDeckFileStatusResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.DECKS}/file/status`,
          data: {
            ...params,
          },
        })

      const currentAiFlowId = sessionStorage.getItem('aiFlowId')
      if (currentAiFlowId !== aiFlowId) {
        return null
      }

      return res.data.data.deckFiles
    } catch {
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const deleteAiFlowFiles = async (params: deleteFileBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.DECKS}/file/delete`,
          data: {
            ...params,
          },
        })

      return res.data.code === 200
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getAiFlowFileUploadUrls,
    checkAiFlowFileStatus,
    getPdfImportAudience,
    getAiFlowAudience,
    getAiFlowAim,
    getAiFlowOutline,
    getAiFlowTheme,
    getPdfImportTheme,
    deleteAiFlowFiles,
    isLoading,
  }
}
