import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const pdfImportStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  height: ${theme.dimensions.pdfImportWidth.DEFAULT}px;
  margin-top: ${theme.gap.xlarge}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${theme.breakpoints.desktop}px) {
    height: ${theme.dimensions.pdfImportWidth.small}px;
  }
`

export const pdfImportAreaStyles = (hover: boolean) => (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  width: auto;
  cursor: pointer;
  height: ${theme.dimensions.pdfImportUploadHeight.DEFAULT}px;
  padding: ${theme.gap.small}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.gap['3xlarge']}px;
  flex-shrink: 0;
  border: 2px solid transparent;
  border-radius: ${theme.borderRadius[6]};
  background-image: ${
    hover
      ? `${theme.gradient.HOVER_BLUE_PURPLE_BORDER};`
      : `${theme.gradient.PURPLE_BORDER};`
  }

  background-origin: border-box;
  background-clip: padding-box, border-box;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.pdfImportUploadWidth.DEFAULT}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: ${theme.dimensions.pdfImportUploadWidth.small}px;
  }
`

export const imageStyles = (theme: ITheme) => css`
  background: transparent;
  display: flex;
  justify-content: center;
  width: ${theme.dimensions.pdfImportWidth.DEFAULT}px;
  height: ${theme.dimensions.pdfImportImageWidth.DEFAULT}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.pdfImportImageWidth.small}px;
    height: ${theme.dimensions.pdfImportImageHeight.small}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: ${theme.dimensions.pdfImportImageWidth.medium}px;
    height: ${theme.dimensions.pdfImportImageHeight.medium}px;
  }
`

export const titleStyles = (hover: boolean) => (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  margin-top: ${theme.gap['3xlarge']}px;
  display: flex;
  justify-content: center;
  color: ${hover
    ? `${theme.colors.white.DEFAULT}`
    : `${theme.colors.text.DEFAULT}`};

  @media (min-width: ${theme.breakpoints.tablet}px) {
    font-size: ${theme.fontSize.medium};
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    font-size: ${theme.fontSize.large};
  }
`

export const subTitleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  margin-top: ${theme.gap.small}px;
  display: flex;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.desktop}px) {
    font-size: ${theme.fontSize.medium};
  }

  & span {
    font-weight: 500;
  }
`
export const errorStyles = (theme: ITheme) => css`
  color: ${theme.colors.red.DEFAULT};
  margin-top: ${theme.gap.small}px;
  font-size: ${theme.fontSize.small};

  @media (min-width: ${theme.breakpoints.laptop}px) {
    font-size: ${theme.fontSize.base};
  }
`
export const sizeErrorStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.red.DEFAULT};
  margin-top: ${theme.gap.small}px;

  @media (min-width: ${theme.breakpoints.laptop}px) {
    font-size: ${theme.fontSize.base};
  }
`

export const inputStyles = (theme: ITheme) => css`
  color: ${theme.colors.red.DEFAULT};
  width: auto;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.pdfImportInputWidth.DEFAULT}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: ${theme.dimensions.pdfImportInputWidth.small}px;
  }
`
export const uploadedPdfStyles = (theme: ITheme) => css`
  color: ${theme.colors.red.DEFAULT};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    flex-direction: row;
    gap: ${theme.gap['2xlarge']}px;
  }
`

export const loadingStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${theme.dimensions.pdfImportUploadHeight.DEFAULT}px;
`

export const pdfImportTextStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const changeButtonStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: flex-start;
  margin-top: ${theme.gap.xlarge}px;
  font-size: ${theme.fontSize.base};
  text-decoration: underline;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    margin-top: 0;
  }
`
