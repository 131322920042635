import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const pdfImportStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  min-height: 383px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const importAreaStyles = (hasFiles: boolean) => (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  width: auto;
  cursor: pointer;
  padding: ${theme.gap.small}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.gap['3xlarge']}px;
  flex-shrink: 0;
  border: 2px dashed ${theme.colors.primary[20]};
  border-radius: ${theme.borderRadius[12]};
  margin-top: ${theme.spacing.xlarge};
  height: ${hasFiles ? 160 : 278}px;
  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.pdfImportUploadWidth.DEFAULT}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: ${theme.dimensions.pdfImportUploadWidth.small}px;
  }
`

export const imageStyles = css`
  background: transparent;
  display: flex;
  justify-content: center;
`

export const titleStyles = (hover: boolean) => (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  margin-top: ${theme.gap.large}px;
  display: flex;
  justify-content: center;
  color: ${hover
    ? `${theme.colors.white.DEFAULT}`
    : `${theme.colors.text.DEFAULT}`};
`

export const subTitleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  margin-top: ${theme.gap['2xsmall']}px;
  display: flex;
  justify-content: center;
  color: ${theme.colors.text[2]};
`

export const errorStyles = (theme: ITheme) => css`
  color: ${theme.colors.red.DEFAULT};
  margin-top: ${theme.gap.small}px;
  font-size: ${theme.fontSize.base};
`

export const sizeErrorStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  color: ${theme.colors.red.DEFAULT};
  margin-top: ${theme.gap.small}px;
  overflow-y: auto;
  height: 38px;
  white-space: pre-line;
`

export const inputStyles = (theme: ITheme) => css`
  color: ${theme.colors.red.DEFAULT};
  width: auto;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: ${theme.dimensions.pdfImportInputWidth.DEFAULT}px;
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    width: ${theme.dimensions.pdfImportInputWidth.small}px;
  }
`
export const uploadedPdfStyles = (theme: ITheme) => css`
  color: ${theme.colors.red.DEFAULT};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: auto;
  gap: ${theme.gap.xlarge}px;
  padding-bottom: ${theme.gap['3xsmall']}px;
  margin-top: ${theme.spacing.xlarge};
`

export const pdfBoxStyles =
  (hasError: boolean) =>
  ({ borderRadius, colors, gap }: ITheme) => css`
    border-radius: ${borderRadius[8]};
    border: 1px solid ${colors.primary[20]};
    width: 108px;
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${gap['xsmall']}px;
    background-color: ${hasError ? colors.red[20] : colors.background.DEFAULT};
    gap: ${gap['4xsmall']}px;
  `

export const iconCloseRowStyles = ({ gap }: ITheme) => css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-right: ${gap['4xsmall']}px;
  padding-top: ${gap['4xsmall']}px;
`

export const fileNameStyles = ({ colors, fontSize }: ITheme) => css`
  width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.black.DEFAULT};
  font-size: ${fontSize.small};
`

export const fileSizeStyles = ({ colors, fontSize }: ITheme) => css`
  color: ${colors.dark[70]};
  font-size: ${fontSize.xsmall};
`
export const uploadingPercentStyles = ({ colors, fontSize }: ITheme) => css`
  color: ${colors.dark[70]};
  font-size: ${fontSize.xsmall};
  text-align: right;
  min-width: 25px;
`

export const fileInfoBoxStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: row;
  gap: ${gap['4xsmall']}px;
  justify-content: space-between;
  align-items: baseline;
`

export const loadingStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${theme.dimensions.pdfImportUploadHeight.DEFAULT}px;
`

export const pdfImportTextStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const changeButtonStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: flex-start;
  margin-top: ${theme.gap.xlarge}px;
  font-size: ${theme.fontSize.base};
  text-decoration: underline;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    margin-top: 0;
  }
`
export const progressBoxStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.gap['2xsmall']}px;
`
