import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  padding: 0 ${theme.spacing.xlarge};
  border-radius: ${theme.borderRadius[2]};
  border: 1px solid ${theme.colors.outline.DEFAULT};
  box-shadow: ${theme.boxShadow[1]};
  width: 100%;

  .item {
    display: flex;
    padding: ${theme.spacing.small} 0;
    gap: ${theme.spacing.xlarge};
    font-size: ${theme.fontSize.small};
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.outline.DEFAULT};
    }

    .text {
      flex-grow: 1;
    }

    .value {
      color: ${theme.colors.text[2]};
    }

    &.clickable {
      cursor: pointer;
      &:hover {
        color: ${theme.colors.primary.DEFAULT};
        path {
          fill: ${theme.colors.primary.DEFAULT};
        }
      }
    }
  }
`
