import React, { useEffect, useState } from 'react'

import { IAiAim } from './types'
import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  INPUT_VARIANT,
  Icon,
  Input,
  icons,
} from 'src/lib'
import {
  buttonStyles,
  aiSuggestionStyles,
  exampleAreaStyles,
  viewStyles,
  inputStyles,
} from '../ai-prompt/styles'
import { Steps } from 'src/lib/steps'
import { useDispatch, useSelector } from 'react-redux'
import { AI_FLOW_STEP, setAiFlowStep, setAim, RootState } from 'src/store'
import {
  VALIDATION_RULE_TYPES,
  useLanguage,
  useValidation,
  useValidatedText,
  useFlags,
} from 'src/hooks'
import { aiAudienceStyles } from '../ai-audience/styles'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import { pdfNameStyles } from '../styles'
import { AiDeckFileStates } from 'src/types/api/enums'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'

export const AiAim: React.FC<IAiAim> = React.memo(({ className, dataAttr }) => {
  const { t } = useLanguage()

  const dispatch = useDispatch()
  const flags = useFlags()
  const posthogCapture = usePostHogCapture()

  const { getAiFlowOutline, getPdfImportTheme } = useAiFlowApi()

  const {
    selectedAim,
    aimSuggestion,
    promptId,
    pdfName,
    pdfImport,
    uploadedFiles,
    aiFlowId,
  } = useSelector(({ aiFlow }: RootState) => ({
    selectedAim: aiFlow.selectedAim,
    aimSuggestion: aiFlow.aimSug,
    promptId: aiFlow.selectedPromptId,
    pdfName: aiFlow.pdfFileName,
    pdfImport: aiFlow.pdfImport,
    uploadedFiles: aiFlow.uploadedFiles,
    aiFlowId: aiFlow.aiFlowId,
  }))

  useEffect(() => {
    aiFlowId &&
      posthogCapture(POSTHOG_EVENTS.AI_FLOW_AIM_VIEW, {
        ai_flow_id: aiFlowId,
      })
  }, [aiFlowId])

  const validatedText = useValidatedText(
    () => {
      nextButton()
    },
    selectedAim,
    3,
  )

  useEffect(() => {
    validatedText
  }, [selectedAim])

  const [isValid] = useValidation({
    value: selectedAim,
    rules: {
      [VALIDATION_RULE_TYPES.MIN]: {
        value: 3,
        text: t('validation.min_char', {
          value: 3,
          name: selectedAim,
        }),
      },
    },
  })

  const [loadingState, setLoadingState] = useState(false)

  const updateSelectedAim = (item: string) => {
    dispatch(setAim(item))
  }

  const onAimChange = (value: string) => {
    dispatch(setAim(value))
  }

  const nextButton = async () => {
    setLoadingState(true)
    if (flags.FE_29_AI_FILE_FLOW) {
      const apiResult = await getAiFlowOutline({
        aim: selectedAim,
        deckPromptId: promptId,
        fileIds: uploadedFiles
          .filter((file) => file.state === AiDeckFileStates.READY)
          .map(({ id }) => id ?? ''),
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.OUTLINE))
        dispatch(setAim(selectedAim))
      }
    } else {
      if (!pdfImport) {
        // Used this block to create with ai flow
        const apiResult = await getAiFlowOutline({
          aim: selectedAim,
          deckPromptId: promptId,
        })
        if (apiResult) {
          dispatch(setAiFlowStep(AI_FLOW_STEP.OUTLINE))
          dispatch(setAim(selectedAim))
        }
      } else {
        // Used this block to pdf import flow
        const apiResult = await getPdfImportTheme({
          aim: selectedAim,
          deckPromptId: promptId,
        })
        if (apiResult) {
          dispatch(setAiFlowStep(AI_FLOW_STEP.THEME))
          dispatch(setAim(selectedAim))
        }
      }
    }
    setLoadingState(false)
  }

  const backButton = () => {
    dispatch(setAiFlowStep(AI_FLOW_STEP.AUDIENCE))
  }

  return (
    <div className={className} {...dataAttr}>
      <div css={viewStyles(false)}>
        <Input
          placeholder={t('create_with_ai.aim_placeholder')}
          value={selectedAim}
          onChange={onAimChange}
          css={inputStyles(false)}
          variant={INPUT_VARIANT.GHOST}
          multiline
        />
        <Steps steps={pdfName ? 3 : 5} current={pdfName ? 2 : 3} />
        {pdfImport && (
          <div css={pdfNameStyles}>
            <Icon icon={icons.pdf}></Icon>
            {pdfName}
          </div>
        )}
        <div css={exampleAreaStyles}>
          <div css={aiAudienceStyles}>
            {aimSuggestion.map((item, index) => (
              <div
                css={aiSuggestionStyles(selectedAim == item)}
                key={index}
                onClick={() => updateSelectedAim(item)}
              >
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div css={buttonStyles}>
        <Button
          type={BUTTON_TYPE.GHOST}
          text={t('common.actions.back')}
          icon={icons.chevron_left}
          isLink
          onClick={backButton}
        />
        <Button
          text={t('common.actions.next')}
          theme={BUTTON_THEME.GRADIENT}
          onClick={nextButton}
          disabled={!isValid}
          isLoading={loadingState}
        />
      </div>
    </div>
  )
})

AiAim.displayName = 'AiAim'
