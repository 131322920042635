export const flags = {
  FE_DECK_THUMBNAIL: true,
  FE_261_AI_EDIT_TEXT: true,
  FE_PLEASE_WAIT_SCREEN_STUCK: true,
  FE_FIX_DEFAULT_COLOR_SELECTION: true,
  FE_421_IMAGE_CORNER_RADIUS: true,
  FE_277_IMAGE_GRID_IMPROVEMENTS: true,
  FE_419_ADMIN_LOGIN_AS_USER: true,
  FE_259_COPY_PASTE: true,
  FE_604_TEMPLATES_DECORS_FONTS: true,
  FE_591_SWAP_BACKGROUND_COLOR_FIX: true,
  FE_579_TEXT_BOX_COLOR_CHANGE: true,
  FE_450_PAYMENT_CONFIRMATION: true,
  FE_544_SLIDE_CARD_CHART_RE_RENDER_FIX: true,
  FE_402_ORGANIZATIONS_REFACTOR: true,
  FE_635_CAPTCHA: true,
  FE_539_MULTIPLE_SELECTION: true,
  FE_617_CHART_IMPROVEMENTS: true,
  FE_558_COMPRESSED_PDF_PPT: true,
  FE_29_AI_FILE_FLOW: true,
}
