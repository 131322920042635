export const texts = {
  // Common
  '': '',
  'common.decktopus': 'Decktopus',
  'common.decks': 'Sunumlar',
  'common.free': 'Ücretsiz',
  'common.description': 'Açıklama',
  'common.name': 'İsim',
  'common.email': 'e-posta',
  'common.full_name': 'İsim ve Soyisim',
  'common.role': 'Görev',
  'common.dashboard': 'Anasayfa',
  'common.analytics': 'Analitikler',
  'common.responses': 'Yanıtlar',
  'common.read_only': 'Salt Okunur',
  'common.read_write': 'Oku & Yaz',
  'common.last': 'Son',
  'common.first': 'Birinci',
  'common.title_asc': 'Başlık (A-Z)',
  'common.title_desc': 'Başlık (Z-A)',
  'common.recent': 'Son Düzenlenen',
  'common.oldest': 'İlk Düzenlenen ',
  'common.personal': 'Kişisel',
  'common.date': 'Tarih',
  'common.auto': 'Otomatik',
  'common.rate': 'Oran',
  'common.text': 'Metin',
  'common.value': 'Değer',
  'common.empty': 'Boş',
  'common.or': `ya da`,
  'common.and': `ve`,
  'common.email_address': `E-posta adresi`,
  'common.password': `Şifre`,
  'common.organization': 'Organizasyon',
  'common.free_uppercased': 'ÜCRETSİZ',
  'common.design': 'Tasarım',
  'common.access': 'Erişim',
  'common.required': 'Gerekli',
  'common.texts': 'Metinler',
  'common.history': 'Geçmiş',
  'common.percent': '%{{value}}',

  'common.files': '{{count}} dosya',
  'common.files_zero': '{{count}} dosya',
  'common.files_one': '{{count}} dosya',
  'common.files_other': '{{count}} dosya',

  'common.slides': '{{count}} slayt',
  'common.slides_zero': '{{count}} slayt',
  'common.slides_one': '{{count}} slayt',
  'common.slides_other': '{{count}} slayt',

  'common.actions.change': 'Değiştir',
  'common.actions.close': 'Kapat',
  'common.actions.complete': 'Tamamla',
  'common.actions.back': 'Geri',
  'common.actions.reset': 'Sıfırla',
  'common.actions.delete': 'Sil',
  'common.actions.create': 'Oluştur',
  'common.actions.share': 'Paylaş',
  'common.actions.search': 'Ara',
  'common.actions.search_with_name': '{{name}} ara',
  'common.actions.check': 'Kontrol et',
  'common.actions.connect': 'Bağlan',
  'common.actions.export': 'Dışa aktar',
  'common.actions.download': 'İndir',
  'common.actions.sort_by': 'Sırala',
  'common.actions.edit': 'Düzenle',
  'common.actions.save': 'Kaydet',
  'common.actions.cancel': 'İptal et',
  'common.actions.add': 'Ekle',
  'common.actions.remove': 'Çıkar',
  'common.actions.rename': 'Yeniden adlandır',
  'common.actions.discard': 'Vazgeç',
  'common.actions.approve': 'Onayla',
  'common.actions.next': 'Sonraki',
  'common.actions.move_to': 'Taşı',
  'common.actions.play': 'Oynat',
  'common.actions.duplicate': 'Çoğalt',
  'common.actions.activate': 'Etkinleştir',
  'common.actions.save_changes': 'Değişiklikleri kaydet',
  'common.actions.paste': 'Yapıştır',
  'common.actions.upload': 'Yükle',
  'common.actions.submit': 'Gönder',
  'common.actions.listen_voice': 'Sesi dinle',
  'common.actions.record_voice': 'Ses kaydet',
  'common.actions.lets_start': `Başlayalım`,
  'common.actions.select': 'Seç',
  'common.actions.preview': 'Önizle',
  'common.actions.skip': 'Geç',

  'common.informative.copied': 'Kopyalandı!',
  'common.informative.click_here': 'buraya tıklayın.',
  'common.informative.coming_soon': 'Çok yakında',
  'common.informative.fail': 'Başarısız',
  'common.informative.processing': 'İşleniyor',
  'common.informative.thanks': 'Teşekkürler!',
  'common.informative.done': 'Tamamlandı',
  'common.informative.payment_successful': 'Ödeme başarılı',
  'common.informative.your_deck_is_ready': 'Sunumunuz hazır.',
  'common.informative.ai_loading_text':
    'Sunumunuz hazırlanıyor, lütfen bekleyiniz...',
  'common.informative.ai_error_text': 'Şu an olağanüstü bir talep yaşıyoruz.',
  'common.informative.wait_on_dashboard': 'Anasayfada bekleyin',
  'common.informative.back_to_first': 'Başa dön',
  'common.informative.ai_high_demand_text':
    'Sunumunuz oluşturulamadı. Şu an olağanüstü bir talep yaşıyoruz.',
  'common.informative.error_start_recording': 'Kayıt başlatma hatası:',
  'common.informative.error_stop_recording': 'Kayıt durdurma hatası:',
  'common.informative.large_media_file':
    'Yüklediğiniz dosya boyutu 5MB üstündedir. Lütfen daha iyi bir deneyim için boyutu daha küçük bir dosya yükleyin.',
  'common.informative.captcha_error':
    'Captcha testinde bir hata oluştu, lütfen tekrar deneyin.',
  'common.informative.captcha_expired':
    'Captcha süre aşımına uğradı, lütfen tekrar deneyin.',
  'common.informative.captcha_challange_expired':
    'Captcha testi zaman aşımına uğradı, lütfen tekrar deneyin.',

  'common.directions.left': 'Sol',
  'common.directions.middle': 'Orta',
  'common.directions.right': 'Sağ',
  'common.directions.up': 'Yukarı',
  'common.directions.center': 'Merkez',
  'common.directions.down': 'Aşağı',

  'common.nth': '{{count}}.',
  'common.nth_one': '{{count}}.',
  'common.nth_two': '{{count}}.',
  'common.nth_three': '{{count}}.',
  'common.nth_other': '{{count}}.',

  'common.files.media': 'Medya',
  'common.files.video': 'Video',
  'common.files.image': 'Görsel',
  'common.files.gif': 'GIF',
  'common.files.icon': 'Simge',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',
  'common.files.pdf_standard': 'PDF Standart',
  'common.files.pdf_compressed': 'PDF Sıkıştırılmış',
  'common.files.ppt_standard': 'PPT Standart',
  'common.files.ppt_compressed': 'PPT Sıkıştırılmış',

  'common.roles.owner': 'Sahibi',
  'common.roles.admin': 'Yönetici',
  'common.roles.member': 'Üye',
  'common.roles.pending': 'Beklemede',

  'common.plan_period.monthly': 'Aylık',
  'common.plan_period.annually': 'Yıllık',

  'common.plan_card.month': ' / ay',

  'common.seats': 'kullanıcı',
  'common.seats_zero': 'kullanıcı',
  'common.seats_one': 'kullanıcı',
  'common.seats_other': 'kullanıcı',

  'common.deck.add_slide': 'Slayt ekle',
  'common.deck.duplicate_slide': 'Slaytı Çoğalt',
  'common.deck.delete_slide': 'Slaytı Sil',
  'common.deck.undo': 'Geri al',

  'common.button': 'Buton',
  'common.template_name': 'Şablon Adı',
  'common.language': 'Dil',

  'common.actions.leave': 'Ayrıl',
  'common.actions.upload_picture': 'Resim Yükle',
  'common.actions.remove_picture': 'Resmi Kaldır',

  'common.informative.error_changing_language':
    'Dil değiştirilirken bir sorun oluştu, lütfen müşteri temsilcisi ile iletişime geçin',
  // Common ends

  // Login page
  'login.welcome': 'Hoş geldiniz!',
  'login.session_expired': 'Oturumunuz sona erdi, lütfen tekrar giriş yapın.',
  'login.continue_with_google': 'Google ile Devam Et',
  'login.login': 'Giriş yap',
  'login.logout': 'Çıkış yap',
  'login.signup': 'Kayıt olun',
  'login.dont_have_an_account': `Hesabınız yok mu?`,
  'login.forgot_password': `Şifrenizi mi unuttunuz?`,
  'login.title1': '2 Ml',
  'login.text1': 'Dünya çapında kullanıcı',
  'login.title2': '64%',
  'login.text2': 'Yapay zeka ile sunum hazırlayarak sağlanan zaman tasarrufu',
  'login.title3': '412b',
  'login.text3': 'Decktopus ile oluşturulan ortalama sunum sayısı',
  'login.title4': '<1 dk',
  'login.text4': 'Müşteri desteği ortalama yanıt süresi',
  'login.incorrect_provider_jwt': `Email ve şifre kullanarak kayıt oldunuz. Lütfen Google ile oturum açmak yerine bu bilgiler ile giriş yapın.`,
  'login.incorrect_provider_google': `Google hesabınız ile kayıt oldunuz. Lütfen email ve şifre ile oturum açmak yerine Google ile giriş yapın.`,
  // Login page ends

  // Expired page
  'expired.get_started': 'Başlayın',
  'expired.expire_link_title': 'Bu bağlantının süresi doldu',
  'expired.expire_link_description':
    'Bu bağlantı artık geçerli değil. Daha fazla bilgi için lütfen kuruluşunuzla iletişime geçin.',
  // Expired page ends

  // Sign up page
  'signup.terms': `Koşullar`,
  'signup.privacy_policy': `Gizlilik Politikası`,
  'signup.full_name': `İsim ve Soyisim`,
  'signup.already_member': `Zaten üye misiniz?`,
  'signup.create_account': 'Hesap oluştur',
  'signup_page_title': 'Hesabınızı oluşturun ve sunum yapmaya başlayın',
  'signup.create_account_info': `Bir hesap oluşturarak <0>koşullarımızı</0> ve <1>gizlilik</1> politikamızı kabul etmiş olursunuz.`,
  'signup.banner': 'Sunumunuzdan sadece bir "tık" uzaktasınız',
  // Sign up page ends

  // Reset password
  'reset.back_to': 'Geri dön',
  'reset.send_email': 'E-posta gönder',
  'reset.resend': 'E-postayı yeniden gönder',
  'reset.new_password': 'Yeni şifre',
  'reset.password_set': 'Şifreniz değiştirildi, lütfen giriş yapın.',
  'reset.reset_password': 'Şifreyi sıfırla',
  'reset.reset_password_info': `E-postanızı girin, size şifrenizi nasıl sıfırlayacağınıza dair talimatları gönderelim.`,
  'reset.password_reset_sent': `Şifre sıfırlama e-postanız gönderildi.`,
  'reset.change_your_password_info':
    'Şifrenizi sıfırlamak için butona tıklayın.',
  'reset.password_reset': 'Şifre Sıfırlama',
  // Reset password ends

  // Auth pages common
  'auth.site_protection': `Bu site hCaptcha ile korunmaktadır ve onun <0>Gizlilik Politikası</0> ve <1>Hizmet Şartlarını</1> uygular.`,
  'auth.terms_of_service': 'Hizmet Şartları',
  // Auth pages common ends

  // Not supported page
  'not_supported': 'desteklenmiyor',
  'not_supported.title':
    'Ne yazık ki düzenleme modu bu cihazda henüz desteklenmiyor. En iyi deneyim için lütfen bir masaüstü bilgisayara geçin.',
  // Not supported page ends

  // Webhook
  'webhook.endpoint_url': `Endpoint URL'niz`,
  'webhook.event_form_responses': 'Etkinlik: Form Yanıtları',
  'webhook.webhook_delete_warning': `“<strong>{{name}}</strong>”'u kalıcı olarak silmek istediğinizden emin misiniz?`,
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Webhook oluştur',
  'webhook.webhook_form_general_info': `Bir webhook oluşturun ve form gönderimlerinde gerçek zamanlı bildirimler alın. Endpoint URL'nizi ekleyin ve yeni webhook'unuzu adlandırın.`,
  'webhook.webhook_learn': `Webhook'ların nasıl kullanılacağını öğrenmek için <0>buraya tıklayın.</0>`,
  // Webhook ends

  // Main panel tabs
  'main_panel.admin': 'Yönetici',
  'main_panel.organization': 'Organizasyon',
  'main_panel.profile_settings': 'Profil Ayarları',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Arkadaşını getir, kazan!',
  'main_panel.subscription': 'Abonelik',
  'main_panel.onboarding': 'Oryantasyon',
  'main_panel.logout': 'Çıkış yap',
  // Main panel tabs ends

  // Profile settings
  'profile.change_account_information': 'Hesap Bilgilerini Değiştir',
  'profile.edit_your_name': 'Adınızı Düzenleyin',
  'profile.change_your_password': 'Şifrenizi Değiştirin',
  'profile.update.save_changes': 'Değişiklikleri Kaydet',
  'profile.update.image_upload_success': 'Profil resminiz değiştirildi.',
  'profile.update.name_change_success': 'İsminiz değiştirildi.',
  'profile.update.name_min_char_error': 'İsminiz en az 3 karakter olmalıdır.',
  'profile.update.image_upload_error': '{{fileType}} desteklenmiyor.',
  'profile.verification_success': 'Hesabınız başarıyla doğrulandı.',
  // Profile settings ends

  // Verify email
  'verify.verify_your_email': 'E-postanızı Doğrulayın',
  'verify.verify_your_account': 'Hesabınızı doğrulayın',
  'verify.verify_your_email_info': 'Hesabınızı doğrulamak için tıklayın.',
  'verify.verification_email_sent': 'Doğrulama e-postası gönderildi.',
  'verify.verification_enter_code': 'Kodunuzu girin',
  'verify.verification_didnt_reveive_email': `E-postamızı almadınız mı? <0>link</0>`,
  'verify.verification_click_to_resend': 'Tekrar göndermek için tıklayın',
  'verify.verification_email_sent_info':
    'Doğrulama kodunu e-posta adresinize gönderdik.',
  // Verify email ends

  // Validations
  'validation.min_char':
    '{{name, capitalFirstLetter}} en az {{value}} karakter olmalıdır.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} en fazla {{value}} karakter olabilir.',
  'validation.error.required': '{{name, capitalFirstLetter}} boş bırakılamaz.',
  'validation.error.email': 'Geçersiz e-posta adresi',
  'validation.error.url': 'Geçersiz URL linki',
  'validation.error.video_url': `Geçersiz video URL'si. Lütfen YouTube veya Vimeo bağlantısını girin.`,
  'validation.validation_user_exists': 'Bu kullanıcı zaten mevcut',
  // Validations ends

  // Layout modal
  'layout.save': 'Sayfa düzenini kaydet',
  'layout.save_new': 'Yeni bir sayfa düzeni olarak kaydet',
  'layout.name': 'Sayfa düzeni adı',
  'layout.blank': 'İçeriksiz',
  // Layout modal ends

  // Workspace selector
  'workspace.personal_hub': 'Kişisel panel',
  // Workspace selector ends

  // Init modal
  'init.category_select_info':
    'Sunum konseptinize uygun bir tasarım kategorisi seçin',
  'init.see_templates': 'Şablonları görüntüle',
  'init.start_from_scratch': 'Sıfırdan başla',
  'init.use_this_template': 'Bu şablonu kullan',

  'init.category0': 'Tümü',
  'init.category1': 'İş',
  'init.category2': 'Teklif',
  'init.category3': 'Çevrimiçi Kurs',
  'init.category4': 'Eğitim',
  'init.category5': 'Topluluk',
  'init.category6': 'Beslenme',
  'init.category7': 'Yönetici Özeti',
  'init.category8': 'Olay Çalışması',
  'init.category9': 'Başlangıç Konuşması',
  // Init modal ends

  // Create with AI modal
  'create_with_ai.generate_presentation': 'Sunum Oluştur',
  'create_with_ai.create_with_ai': 'AI ile Oluştur',
  'create_with_ai.prompt_placeholder': 'Sunum konunuzu buraya yazın!',
  'create_with_ai.audience_placeholder': 'Hedef kitlenizi yazın',
  'create_with_ai.aim_placeholder': 'Amacınızı yazın',
  'create_with_ai.prompt_title': 'Sunumunuz ne hakkında?',
  'create_with_ai.audience_title': 'Hedef kitleniz kim?',
  'create_with_ai.aim_title': 'Bu sunum sonundaki amacınız nedir?',
  'create_with_ai.outline_title':
    'Ana hatları değiştirebilir veya değiştirmeden devam edebilirsiniz.',
  'create_with_ai.theme_title': 'Hangi şablonu kullanmak istersiniz?',
  'create_with_ai.theme_subtitle':
    'Endişelenmeyin! İstediğiniz zaman değiştirebilirsiniz.',
  'create_with_ai.ai_subtitle': `Deckto'ya aklınızdaki bir başlıkla ilham verin. Sunum konunuzu şu şekilde yazmayı deneyin: “Roma Tarihi”`,
  'create_with_ai.see_examples': 'Örnekleri gör',
  'create_with_ai.hide_examples': 'Örnekleri gizle',
  'create_with_ai.import_pdf': `PDF'nizi içe aktarın`,
  'create_with_ai.pdf_subtitle':
    'Deckto, belgenizi özenle düzenlenmiş slaytlara dönüştürecek.',
  'create_with_ai.category_title': 'Sunumunuz için bir kategori seçin.',
  'create_with_ai.category.option1': 'Girişim Sunumu',
  'create_with_ai.category.option2': 'İş Teklifi',
  'create_with_ai.category.option3': 'Satış Sunumu',
  'create_with_ai.category.option4': 'Rapor Sunumu',
  'create_with_ai.category.option5': 'Eğitsel',
  'create_with_ai.category.option6': 'Diğer',
  'create_with_ai.strength.low':
    'Birkaç kelime daha, derin içgörüler dünyasının kapılarını açabilir!',
  'create_with_ai.strength.medium':
    'Yarı yoldasınız—tam resmi ortaya çıkarmak için biraz daha ekleyin!',
  'create_with_ai.strength.high':
    'Harika! Ne kadar çok bağlam sağlarsanız, sonuçlar o kadar net olur.',
  'create_with_ai.strength.done':
    'Mükemmel! İsterseniz daha fazla eklemeye devam edebilirsiniz!',
  'create_with_ai.strength.tooltip':
    'Ayrıntılı yönergeler daha keskin, daha alakalı içgörülere yol açar; en iyi sonuçları elde etmek için daha fazla bağlam sunar!',
  // Create with AI modal ends

  // Pdf import modal
  'pdf_import.drag_and_drop_file': 'Dosyanızı sürükleyip bırakın',
  'pdf_import.on_your_computer': 'bilgisayarınızda',
  'pdf_import.browse_file': 'dosya ara',

  'pdf_import.drag_and_drop': 'Dosyalarınızı sürükleyin veya göz atın',
  'pdf_import.browse_file_on_your_device': 'Cihazınızda dosya arayın',
  'pdf_import.pdf_size_error':
    'Yüklediğiniz PDF boyutu çok büyük, daha küçük bir dosya ile deneyin.',
  'pdf_import.use_pdf_format':
    'Yüklenemiyor. .PDF formatında bir dosya kullanın.',
  'pdf_import.drop_the_file_to_upload': 'Yüklemek için dosyayı bırakın',
  // Pdf import modal ends

  // File import modal
  'file_import.import_file_title': '(İsteğe bağlı) Belgelerinizi yükleyin',
  'file_import.import_file_info':
    'Sunumunuza eklemek veya referans vermek istediğiniz belgeleri yükleyin.',
  'file_import.use_file_format':
    '{{fileName}} dosyası yüklenemiyor. Aşağıdaki formatlarda bir dosya kullanın: docx, json, md, pdf, pptx, txt',
  'file_import.maximum_files': 'En fazla 5 dosya yükleyebilirsiniz',
  'file_import.maximum': 'Maksimum dosya yükleme sınırına ulaştınız!',
  'file_import.maximum_info':
    'Başka bir dosya yüklemek için lütfen birini silin.',
  'file_import.drag_and_drop': 'Dosyalarınızı sürükleyin veya göz atın',
  'file_import.size_error':
    '{{fileName}} dosyası çok büyük. Daha küçük bir tane deneyin.',
  'file_import.already_uploaded':
    '{{fileName}} dosyası zaten yüklendi. Başka bir tane deneyin.',
  'file_import.file_state.ready': 'Hazır',
  'file_import.file_state.error': 'Hata',
  'file_import.file_state.processing': 'İşleniyor',
  'file_import.file_state.ready_info': 'Dosya başarıyla yüklendi.',
  'file_import.file_state.error_info':
    'Üzgünüz, beklenmedik bir hata oluştu. Lütfen başka bir dosya yüklemeyi deneyiniz.',
  'file_import.file_state.processing_info': 'Dosya işleniyor, lütfen bekleyin.',
  // File import modal ends

  // Organization page
  'organization.organization_profile_update.image_upload_success':
    'Kuruluş resmi değişti.',
  'organization.organization_profile_update.name_and_description_success':
    'Kuruluş detayları değişti.',
  'organization.organization_details': 'Kuruluş detayları',
  'organization.remaining_seats': 'Kalan kullanıcı kotası',
  'organization.manage_seats': 'Kullanıcıları yönet',
  'organization.used_seats':
    '{{totalSeats}} kullanıcıdan {{occupiedSeats}} kadarı aktif',
  'organization.adjust_seat_number': 'Kullanıcı sayısını düzenle',
  'organization.current_users': 'Mevcut kullanıcılar ({{count}}) Ücret / 12',
  'organization.added_users': 'Eklenen kullanıcılar ({{count}}) Ücret / 12',
  'organization.updated_annual_total':
    'Güncellenen {{period}} Toplam Ücret / 12',
  'organization.first_charge': 'İlk ücret orantılı olarak hesaplanacaktır.',
  'organization.first_charge_tooltip':
    'Ek kullanıcıların ilk ücreti, mevcut fatura döngünüzde kalan gün sayısına göre hesaplanır.',
  'organization.reset_invitation': 'Davetiye yeniden gönderildi',
  'organization.cancel_invitation': 'Davetiyeyi iptal et',
  'organization.member_settings': 'Üye ayarları',
  'organization.add_members': 'Üye ekle',
  'organization.add_members_title': 'Kuruluşunuza üyeler ekleyin',
  'organization.not_enough_seats':
    'Daha fazla üye eklemek için yeterli yeriniz yok. Kuruluş sayfasından kullanıcı sayısını düzenleyerek yer açın.',
  'organization.add_member_information':
    'Üyeleri her zaman “Benim Kuruluşum” sayfasındaki sağ sekmeden kuruluşunuza davet edebilirsiniz.',
  'organization.organization-details': 'Kuruluş detayları',
  'organization.leave_team_title': 'Takımdan ayrıl',
  'organization.leave_team_button': 'Kuruluştan ayrıl',
  'organization.leave_team_dialog_text':
    'Bir kuruluştan ayrıldığınızda işbirliği yapılan sunumlara artık erişemezsiniz.',
  'organization.leave_team_text':
    'Eğer takımdan ayrılırsanız, kuruluş içeriğine ve iş özelliklerine artık erişemezsiniz.',
  'organization.organization_name_label': 'Kuruluş ismi',
  'organization.organization_desc_label': 'Kuruluş açıklaması',
  'organization.organization_team_logo': 'Takım logosu',

  'organization.delete_member': 'Üyeyi sil',
  'organization.delete_member_title':
    '“{{name}}” adlı üyeyi kalıcı olarak silmek istediğinizden emin misiniz?',
  'organization.delete_member_text': `“{{name}}” artık sunumları ve kuruluş detaylarını göremez.`,

  'organization.resend_info':
    '{{email}}, henüz davetiyenizi henüz kabul etmedi. Bir hatırlatma olarak davetiyeyi yeniden göndereceksiniz.',
  'organization.organization_suspended':
    'Bu kuruluş askıya alındı. Sorunu çözmek için ödeme yönteminizi güncelleyin.',
  'organization.organization_suspended_member':
    'Bu kuruluş askıya alındı. Daha fazla bilgi için lütfen kuruluş yöneticisiyle iletişime geçin.',
  // Organization page ends

  // Refer
  'refer.invite_friend': 'Arkadaşını Davet Et!',
  'refer.invite_friend_text': `Siz ve arkadaşınız <strong>30</strong> kredi kazanacaksınız.`,
  'refer.referred': 'Arkadaşınıza bir e-posta gönderildi',
  // Refer ends

  // Analytics
  'analytics.no_views_yet': 'Henüz görüntüleme yok',
  'analytics.share_this_deck': 'Bu sunumu paylaş',
  'analytics.total_views': '{{view}} Toplam Görüntüleme',
  'analytics.total_views_one_slide': '{{view}} İlk slayt için görüntülemeler',
  'analytics.chart_description':
    'Bu grafik, sunumlarınızın görüntüleme performansını temsil eder',
  'analytics.last_time_open': 'Son açılma zamanı',
  'analytics.views': '{{view}} Görüntüleme',
  'analytics.openedTimes': '{{view}} kez bakıldı',
  // Analytics ends

  // Subscriptions
  'subscriptions.upgrade': 'Yükselt',
  'subscriptions.show_more': 'Daha fazla göster',
  'subscriptions.show_less': 'Daha az göster',
  'subscriptions.subscription': 'Abonelik',
  'subscriptions.current_plan': 'Mevcut plan',
  'subscriptions.credit_information': 'Kredi bilgisi',
  'subscriptions.ai_credits': 'AI Kredileri',
  'subscriptions.choose_plan': `Plan seç`,
  'subscriptions.plan_renew_info': 'Planınız {{date}} tarihinde yenilenecek',
  'subscriptions.plan_cancel_info':
    'Aboneliğiniz {{date}} tarihinde sonlandırılacak',
  'subscriptions.plan_renew_info_free':
    'Muhteşem AI özelliklerine erişmek için hesabınızı yükseltin!',
  'subscriptions.credit_renew_info':
    'Kredileriniz {{date}} tarihinde yenilenecek',
  'subscriptions.credit_cancel_info':
    'Kredilerinizi {{date}} tarihine kadar kullanabilirsiniz',
  'subscriptions.credit_history': 'Kredi geçmişi',
  'subscriptions.credit_history_info':
    'Kredi geçmişi, abonelik döneminin başından itibaren olan harcamaları yansıtır',
  'subscriptions.subscription_settings': 'Abonelik ayarları',
  'subscriptions.change_payment_method': 'Ödeme Yöntemini Değiştir',
  'subscriptions.update_billing_info': 'Fatura Bilgilerini Güncelle',
  'subscriptions.invoices': 'Faturalar',
  'subscriptions.cancel_subscription': 'Aboneliği İptal Et',
  'subscriptions.renew_subscription': 'Aboneliği Yenile',

  'subscriptions.cancel_promo_modal.title': '3 AY BOYUNCA %75 İNDİRİM',
  'subscriptions.cancel_promo_modal.subtitle':
    'Aboneliğinizi iptal etmek üzere olduğunuzu fark ettik',
  'subscriptions.cancel_promo_modal.description':
    "Değerli bir kullanıcı olarak, Decktopus'ta kalmaya karar verirseniz, aboneliğinizin bir sonraki {{period}} size özel <strong>{{discount}}</strong> sunmak istiyoruz.",
  'subscriptions.cancel_promo_modal.discount_monthly': '%75 indirim',
  'subscriptions.cancel_promo_modal.discount_annually': '%25 indirim',
  'subscriptions.cancel_promo_modal.period_monthly': '3 ayında',
  'subscriptions.cancel_promo_modal.period_annually': 'yilinda',
  'subscriptions.cancel_promo_modal.cancel': 'Aboneliğimi iptal et',
  'subscriptions.cancel_promo_modal.apply': 'İndirimi Uygula',
  'subscriptions.cancel_promo_modal.success': 'İndirim başarıyla uygulandı',

  'subscriptions.cancel_reason_dialog.title':
    'Deneyiminizi iyileştirmek için neyi farklı yapabilirdik?',
  'subscriptions.cancel_reason_dialog.subtitle': 'En az 50 karakter',
  'subscriptions.cancel_reason_dialog.submit': 'Geri bildirim gönder',
  'subscriptions.cancel_reason_dialog.input_placeholder':
    'Deneyiminizi buraya yazın.',
  'subscriptions.cancel_reason_dialog.input_label': 'Geri bildirim',
  'subscriptions.cancel_reason_dialog.footer_text':
    'Geri bildiriminiz bizim için inanılmaz derecede değerli. Gelecekte sizin ve başkaları için daha iyi bir deneyim yaratmamıza ve geliştirmemize yardımcı oluyor. En küçük içgörüler bile büyük bir fark yaratabilir. Paylaştığınız için teşekkür ederiz!',

  'subscriptions.plan.powerup_info':
    '{{plan_name}} planımı güçlendirmek istiyorum',
  'subscriptions.plan.choose_pro': `Pro'yu seç`,
  'subscriptions.plan.choose_business': 'İş planını seç',
  'subscriptions.plan.discount_info': `40%'a kadar tasarruf edin`,

  'subscriptions.subs_state.pending': 'Beklemede',
  'subscriptions.subs_state.action_required': 'Eylem gerekiyor',
  'subscriptions.subs_state.failed': 'Başarısız',
  'subscriptions.subs_state.canceled': 'İptal edildi',
  'subscriptions.subs_state.active': 'Aktif',
  'subscriptions.plan.popular': 'Popüler',
  'subscriptions.billed_annually': 'yıllık faturalandırılır',
  'subscriptions.update_payment': 'Ödeme yönteminizi güncelleyin',
  // Subscriptions ends

  // Upgrade modal
  'upgrade_modal.upgrade_to_business': `İş planı'na yükselt`,
  'upgrade_modal.upgrade_to_business_text':
    'Sunumlarınızı bir üst seviyeye taşıyın ve onlardan elde ettiğiniz getiriyi arttırın.',
  'subscriptions.plan.cancel_anytime': 'İstediğiniz zaman iptal edin',
  'upgrade_modal.refill_your_credits': 'Kredilerinizi yenileyin',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'İlerlemenizi kesintiye uğratmayın. Planınızı yükselterek çalışmaya devam edin.',
  'upgrade_modal.upgrade_modal_subtitle': `Sunumlarınızda Decktopus'un yapay zekasından yararlanın.`,
  'upgrade_modal.choose_plan': 'Bir plan seçin',
  // Upgrade modal ends

  // Dashboard page
  'dashboard.create.create_presentation': 'Sunum oluştur',
  'dashboard.create.start_from_scratch': 'Sıfırdan başla',
  'dashboard.create.create_with_a_template': 'Şablonla sunum oluştur',

  'dashboard.folder.folder': 'Klasör',
  'dashboard.shared_with_me': 'Benimle paylaşılanlar',
  'dashboard.folder.create_new_folder': 'Yeni klasör oluştur',
  'dashboard.folder.create_folder': 'Klasör oluştur',
  'dashboard.folder.delete_title':
    '“{{folder_name}}” ve içindeki {{folder_id}} dosyaları kalıcı olarak silmek istediğinizden emin misiniz?',
  'dashboard.folder.delete_text': `Silinen dosyalar kurtarılamaz.`,
  'dashboard.folder.folder_is_created': 'Klasör oluşturuldu.',
  'dashboard.folder.folder_deleted': 'Klasör silindi.',
  'dashboard.folder.type_folder_name': 'Klasör adınızı buraya yazın.',
  'dashboard.folder.rename': 'Klasörü yeniden adlandır',
  'dashboard.folder.renamed': 'Klasör yeniden adlandırıldı',
  'dashboard.folder.name': 'klasör adı',

  'dashboard.card.rename_deck': 'Sunumu yeniden adlandır',
  'dashboard.card.type_deck_name': 'Sunumunuzun adını buraya yazın.',
  'dashboard.card.deck_name': 'Sunum adı',
  'dashboard.card.deck_renamed': 'Sunum yeniden adlandırıldı.',
  'dashboard.card.deck_delete_text':
    '“{{deckName}}” sunumunu silmek istediğinizden emin misiniz?',
  'dashboard.card.remove_me_text':
    'Kendinizi “{{deckName}}” sunumundan çıkarmak istediğinizden emin misiniz?',
  'dashboard.card.remove_me': 'Beni çıkar',
  'dashboard.card.file_not_recover': 'Silinen dosyalar kurtarılamaz.',
  'dashboard.card.deck_deleted': 'Sunum silindi',
  'dashboard.card.deck_removed': 'Sunumdan çıkarıldınız',
  'dashboard.card.transferring': 'Sunumunuz eski versiyondan aktarılıyor...',
  'dashboard.card.migration': 'Aktar',
  'dashboard.card.publish_template': 'Şablonu yayınla',
  'dashboard.card.unpublish_template': 'Şablonu yayından kaldır',

  'deck_not_created': 'Sunum oluşturulamadı',

  'dashboard.search.search_my_decks': `Decktopus'ta sunumlarımı ara`,
  'dashboard.search.results_for': '“{{searchParam}}” için sonuçlar:',
  'dashboard.search.no_results_for':
    '<strong>{{ searchParam }}</strong> ile eşleşen sonuç bulunamadı',
  'dashboard.search.try_another_search':
    'Aradığınızı bulabilmek için aramanızı düzenlemeyi deneyin',
  'dashboard.search.return_dashboard': 'veya kontrol paneline dönün',
  'dashboard.search.search_on_decktopus': `Şablonu Decktopus'ta ara`,

  'dashboard.publish.success': 'Your deck is published successfully',
  'dashboard.unpublish.success': 'Your deck is unpublished successfully',
  'dashboard.publish.error': 'Deck could not published',
  'dashboard.unpublish.error': 'Deck could not unpublished',
  // Dashboard page ends

  // Form responses
  'form_response.form_responses': 'Form yanıtları',
  'form_response.table.download_form': 'Formu indir',
  'form_response.table.deleted_question': 'Silinmiş soru',
  'form_response.no_answers_yet': 'Henüz cevap yok.',
  'form_response.table.avarage_score': 'Ortalama skor',
  // Form responses end

  // Edit page
  'edit.component_fix.detail': 'Final adjustments are being made to your deck.',
  'edit.component_fix.info': 'Please wait',
  'edit.slide_menu.layout': 'sayfa düzeni',

  'edit.create_slide.no_custom_layout_text': `Henüz özel bir sayfa düzeni oluşturmadınız.`,
  'edit.create_slide.no_shared_layout_text': `Paylaşılan herhangi bir sayfa düzeniniz yok.`,

  'edit.color_picker.select_color': 'Renk seç',
  'edit.color_picker.solid': 'Sabit renk',
  'edit.color_picker.gradient': 'Geçişli',
  'edit.color_picker.theme_colors': 'Tema renkleri',
  'edit.color_picker.recent_colors': 'Son renkler',

  'edit.media.modal.image.unsplash_library': 'Unsplash kütüphanesinden ',
  'edit.media.modal.image.title': '<0>Unsplash kütüphanesinden</0> görsel seç',
  'edit.media.modal.title': 'Kütüphaneden {{type}} seç',
  'edit.media.modal.load_more': 'Daha fazla yükle',
  'edit.media.modal.no_result': 'Sonuç yok',
  'edit.media.modal.initial_text':
    '{{type}} aramak için lütfen bir metin girin',
  'edit.media.modal.ai.input': 'açıklama',
  'edit.media.modal.select_media': '{{ type }} seç',
  'edit.media.modal.search_media': '{{ type }} ara',

  'edit.list.submenu.placeholder': 'çoklu',
  'edit.list.layout.columns': 'Kolonlar',
  'edit.list.layout.rows': 'Satırlar',

  'edit.create_slide.copy_from_another_deck': 'Başka bir sunumdan kopyala',
  'edit.header.title': 'Başlık bileşenini düzenle',

  'edit.elements.embeds': 'Gömülü Kod',
  'edit.elements.interactive_elements': 'Etkileşimli bileşenler',
  'edit.elements.see_all': 'Hepsini gör',
  'edit.elements.see_less': 'Daha az gör',
  'edit.elements.shapes': 'Şekiller',
  'edit.elements.tooltip_text': 'Bir slayta sadece 1 form eklenebilir',
  'edit.elements.content_blocks': 'İçerik blokları',

  'edit.canvas.blank': 'Boş',
  'edit.canvas.context_menu.bring_forward': 'Öne getir',
  'edit.canvas.context_menu.send_backward': 'Geri gönder',
  'edit.canvas.context_menu.bring_to_front': 'En öne getir',
  'edit.canvas.context_menu.send_to_back': 'En geriye gönder',
  'edit.canvas.context_menu.send_to_background': 'Arka plana gönder',

  'edit.canvas.context_menu.edit_with_ai': 'AI ile düzenle',
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Özetle',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Düzelt',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Tekrar yaz',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Genişlet',
  'edit.canvas.context_menu.edit_with_ai.other': 'Diğer',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Oluştur',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Çıktınızı buraya yazın',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Seçili içeriği iyileştirmek için Decktopus`a bir çıktı verin',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'Açıklama 3 karakterden daha kısa olamaz',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    'Düzeltilecek bir yazım yanlışı bulunamadı',

  'edit.canvas.paste.processing_image': 'Görsel işleniyor...',
  'edit.canvas.paste.image_pasted': 'Görsel yüklendi!',

  'edit.ai.create_qa_text':
    'Sunumunuza özel Soru & Cevap bölümünü görün. Sunumunuz sırasında sorulabilecek sorular ve yanıtları bu bölümdedir.',
  'edit.ai.create_qa_button': 'Soru & Cevap oluştur',
  'edit.ai.create_tip_text':
    'Bu bölümde daha iyi bir sunum deneyimi yaşamanız için slayt ipuçları sunulmaktadır. Size giriş, sohbet başlatıcılar ve hikaye anlatımı önerilerimizle yardımcı oluyoruz.',
  'edit.ai.create_tip_button': 'İpuçları oluştur',
  'edit.ai.create_tips_hover':
    'Bu özelliği kullanmak için AI sunumu oluşturun.',

  'edit.canvas_sidebar.start_show': 'Gösteriyi başlat',
  'edit.canvas_sidebar.start_voice': 'Sesi başlat',
  'edit.canvas_sidebar.swap_colors': 'Renkleri değiştir',

  'edit.design.customize_design': 'Tasarımı özelleştir',
  'edit.design.font_family': 'Yazı tipi ailesi',
  'edit.design.upload_custom_font': 'Özel yazı tipi yükle',
  'edit.design.upload_your_font': 'Yazı tipinizi buraya yükleyin',
  'edit.design.allowed_fonts_text':
    'Lütfen yalnızca .ttf ve .otf formatında yazı tipleri yükleyiniz*.',
  'edit.design.custom_font_responsibility':
    '*Yüklenen yazı tiplerinin ticari kullanımından doğan tüm sorumluluk kullanıcıya aittir. Decktopus, ticari kullanım için herhangi bir sorumluluk kabul etmez.',
  'edit.design.upload_primary_font': 'Birincil yazı tipini yükle',
  'edit.design.upload_secondary_font': 'İkincil yazı tipini yükle',
  'edit.design.color_palette': 'Renk paleti',
  'edit.design.default_color_palettes': 'Varsayılan paletler silinemez.',

  'edit.preferences.background_color': 'Arka plan rengi',
  'edit.preferences.background_tooltip':
    'Arka plan rengini değiştirmek için önce alttan dekor seçin.',
  'edit.preferences.background_decor': 'Arka plan dekoru',

  'edit.properties.list.submenu.title': 'Metin & Simge Özellikleri',
  'edit.properties.list.new_list.add_new': 'Yeni ekle',

  'edit.properties.quote.alignment': 'Alıntı Hizalama',
  'edit.properties.quote.placeholder': 'Alıntı',
  'edit.properties.quote.author': 'Yazar',
  'edit.properties.quote.size.label.text': 'Alıntı',
  'edit.properties.quote.size.label.author': 'Yazar',

  'edit.properties.data': 'Veriyi düzenle',
  'edit.properties.table': 'Tabloyu düzenle',
  'edit.properties.show_header_column': 'Başlık kolonunu göster',
  'edit.properties.show_header_row': 'Başlık satırını göster',
  'edit.properties.form.submenu.title': 'Form özellikleri',
  'edit.properties.form.button_text': 'buton metni',
  'edit.properties.form.placeholder_text': 'Varsayılan metin',
  'edit.properties.form.question_text': 'soru metni',
  'edit.properties.form.choice': 'seçim',
  'edit.properties.form.header': 'Buraya form türü ekle',
  'edit.properties.form.header.button_text': 'Buton metni',
  'edit.properties.form.option_list': 'Seçenek listesi',
  'edit.properties.form.check_list': 'Kontrol listesi',
  'edit.properties.form.free_text': 'Serbest metin',
  'edit.properties.form.submitting_required_tooltip':
    'Bu açık olduğunda, kullanıcı bu formu gönderdikten sonraki slaytları göremez.',

  'edit.properties.button_props.paste_url': `Bir web sitesi URL'si yapıştırın.`,
  'edit.properties.button_props.outlined': 'Çerçeveli',

  'edit.properties.chart_props.show_grid': 'Kılavuzu göster',
  'edit.properties.chart_props.show_legends': 'Açıklamaları göster',
  'edit.properties.chart_props.show_background': 'Arkaplanı göster',
  'edit.properties.chart_props.opacity': 'Arkaplan şeffaflığı',
  'edit.properties.chart_props.background_color': 'Arkaplan rengi',

  'edit.properties.chart_title.placeholder': 'Grafik başlığı',
  'edit.properties.edit_data.error': 'bir sayı değil',

  'edit.properties.x_axis.show_x_axis': 'X Eksenini göster',
  'edit.properties.x_axis.x_axis': 'X Ekseni',
  'edit.properties.y_axis.show_y_axis': 'Y Eksenini göster',
  'edit.properties.y_axis.y_axis': 'Y Ekseni',

  'edit.properties.form_choice.choice': 'Seçim',
  'edit.properties.form_props.question': 'Soru',
  'edit.properties.form_props.description': 'Açıklama',
  'edit.properties.form_props.choice_1': 'Seçim 1',
  'edit.properties.form_props.choice_2': 'Seçim 2',
  'edit.properties.form_props.choice_3': 'Seçim 3',

  'edit.properties.form_question_option.add_choice': 'Seçim ekle',
  'edit.properties.form_question_rate.choose_icon': 'Simge stili seç',

  'edit.properties.chart.chart_data': 'Grafik türü:',

  'edit.properties.shape.placeholder': 'Şekil',
  'edit.properties.shape.distort': 'Boz',

  'edit.properties.ai_image.create': 'AI ile oluştur',
  'edit.properties.ai_image.generate_with_ai': 'AI ile bir görsel oluştur',
  'edit.properties.ai_image.describe': `Talebinizi AI'a açıklayın`,
  'edit.properties.ai_image.generate': 'Oluştur',

  'edit.properties.table.placeholder': 'Ayarlar',

  'edit.properties.text_size.placeholder': 'Çoklu',
  'edit.properties.text_size.big_text': 'Büyük metin',
  'edit.properties.text_size.title': 'Başlık',
  'edit.properties.text_size.subtitle': 'Alt başlık',
  'edit.properties.text_size.body': 'Metin',
  'edit.properties.text_size.label': 'Küçük metin',

  'edit.properties.text_props.header': 'Metin',
  'edit.properties.text_props.reset': 'Sıfırla',
  'edit.properties.text_props.fill': 'Doldur',
  'edit.properties.text_props.opacity': 'Opaklık',

  'edit.properties.video_props.video': 'Video',
  'edit.properties.video_props.paste':
    'YouTube veya Vimeo video bağlantısını yapıştırın.',

  'edit.properties.website_props.website': 'Web sitesi',
  'edit.properties.website_props.paste': `Bir web sitesi URL'si yapıştırın.`,

  'edit.properties.list.list_type': 'Liste Türü',
  'edit.properties.list.list_direction': 'Yön',
  'edit.properties.list.media_position': 'Medya konumu',
  'edit.properties.list.layout': 'Sayfa düzeni',
  'edit.properties.list.colors': 'Renkler',
  'edit.properties.list.font_size': 'Yazı tipi boyutu',
  'edit.properties.list.size': 'Boyut',
  'edit.properties.list.icon_frame': 'Simge çerçevesi',
  'edit.properties.list.color.icon': 'Simge',
  'edit.properties.list.color.icon_frame': 'Çerçeve',
  'edit.properties.list.color.number': 'Numara',
  'edit.properties.list.color.bullet': 'Madde işareti',
  'edit.properties.list.font_title': 'Başlık',
  'edit.properties.list.font_body': 'Gövde',
  'edit.properties.list.font_logo': 'Logo',

  'edit.properties.form.form_properties': 'Form Özellikleri',
  'edit.properties.form.gdpr_message': 'Veri Koruma Mevzuatı Mesajı',
  'edit.properties.form.submitting_required': 'Form gönderimi gerekli',
  'edit.properties.form.consent': 'Veri Koruma Mevzuatı onayını etkinleştir',
  'edit.properties.form.gdpr_tooltip':
    'Bu açık olduğunda, gönder butonunun üzerinde bir onay kutusu görünür.',
  'edit.properties.form.gdpr_prop_placeholder': 'Serbest Metin Girişi Etiketi',
  'edit.properties.form.option_list_placeholder': 'Seçenek Listesi Etiketi',
  'edit.properties.form.rate_placeholder': 'Puanlama Etiketi',
  'edit.properties.form.back_button_text': 'Geri butonu metni',
  'edit.properties.form.next_button_text': 'İleri butonu metni',
  'edit.properties.form.submit_button_text': 'Gönder butonu metni',
  'edit.properties.form.submit_message': 'Mesajı gönder',
  'edit.properties.font.select_option': 'Seçenek seç',

  'edit.add_new_slide_modal.slide_layouts': 'Slayt sayfa düzeni',
  'edit.add_new_slide_modal.shared_with_me': 'Benimle paylaşılanlar',
  'edit.add_new_slide_modal.custom_layouts': 'Özel sayfa düzenleri',
  'edit.add_new_slide_modal.search_slide_type':
    'Sayfa düzeni kütüphanenizden bir slayt türü arayın, örneğin: “Resim”',

  'edit.panel.menu_tabs.slide_notes': 'Slayt notları',
  'edit.panel.menu_tabs.transcripts': 'Transkriptler',
  'edit.panel.menu_tabs.tips': 'İpuçları',
  'edit.panel.menu_tabs.qa': 'Soru & Cevap',
  'edit.panel.menu_tabs.themes': 'Temalar',
  'edit.panel.menu_tabs.style': 'Stil',
  'edit.panel.menu_tabs.colors': 'Renkler',

  'edit.panel.preferences': 'Tercihler',
  'edit.panel.elements': 'Bileşenler',
  'edit.panel.notes': 'Notlar',
  'edit.panel.ai_assistant': 'AI Asistanı',
  'edit.panel.brand': 'Marka',

  'edit.slide_menu.warning': 'Uyarı',
  'edit.slide_menu.warning_text':
    'Mevcut bir sayfa düzenini değiştirmek üzeresiniz, devam etmek istiyor musunuz?',

  'edit.slide_menu.replace': 'Değiştir',

  'edit.design.default_fonts_delete': 'Varsayılan yazı tipleri silinemez.',

  'edit.properties.media.image_props': 'Resim Özellikleri',
  'edit.properties.media.image_opacity': 'Şeffaflık',
  'edit.properties.media.corner_radius': 'Köşe Çapı',

  'edit.properties.icon.styles': 'Stiller',
  'edit.properties.icon.icon': 'Simge',
  'edit.properties.icon.background': 'Arka Plan',

  'edit.properties.chart_props.settings': 'Ayarlar',

  'edit.properties.device.header': 'Bağlantı',
  'edit.properties.device.change_device': 'Cihazı Değiştir',
  'edit.properties.device.laptop': 'Dizüstü Bilgisayar',
  'edit.properties.device.tablet': 'Tablet',
  'edit.properties.device.phone': 'Telefon',

  'edit.properties.alignment.position': 'Pozisyon',

  'edit.properties.list.color.title': 'Başlık',
  'edit.properties.list.color.body': 'Başlık 2',

  'edit.properties.zoom_types.fill': 'Doldur',
  'edit.properties.zoom_types.fit': 'Sıkıştır',
  'edit.properties.zoom_types.distort': 'Boz',
  'edit.properties.zoom_types.crop': 'Kırp',

  'edit.definitions.bar_chart.display_name': 'Çubuk Grafik',
  'edit.definitions.bar_chart.name1': 'Mühendislik',
  'edit.definitions.bar_chart.name2': 'Pazarlama',
  'edit.definitions.bar_chart.name3': 'Operasyon',
  'edit.definitions.body.text': 'Yeni Başlık',
  'edit.definitions.body.display_name': 'Bir başlık ekleyiniz',
  'edit.definitions.bullet_list.display_name': 'Madde Listesi',
  'edit.definitions.bullet_list.item_title1': 'Madde 1',
  'edit.definitions.bullet_list.item_title2': 'Madde 2',
  'edit.definitions.bullet_list.item_title3': 'Madde 3',
  'edit.definitions.bullet_list.item_body1': 'Madde 1 Açıklaması',
  'edit.definitions.bullet_list.item_body2': 'Madde 2 Açıklaması',
  'edit.definitions.bullet_list.item_body3': 'Madde 3 Açıklaması',
  'edit.definitions.button.display_name': 'Buton',
  'edit.definitions.button.text': 'Buton',
  'edit.definitions.code.display_name': 'Kod',
  'edit.definitions.device.display_name': 'Cihaz',
  'edit.definitions.donut_chart.display_name': 'Donut Grafik',
  'edit.definitions.donut_chart.label': 'Serisi1',
  'edit.definitions.donut_chart.value1': 'Alfa',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Echo',
  'edit.definitions.form.display_name': 'Form',
  'edit.definitions.form.title': 'Form Başlığı',
  'edit.definitions.form.button_text': 'Gönder',
  'edit.definitions.form.answers.text': 'Metin tipi',
  'edit.definitions.form.answers.option': 'Seçenek tipi',
  'edit.definitions.form.answers.rate': 'Puanlama tipi',
  'edit.definitions.form.buttons.next': 'Sonraki',
  'edit.definitions.form.buttons.back': 'Geri',
  'edit.definitions.form.submit': 'Teşekkürler',
  'edit.definitions.form.gdpr_consent_message': 'GDPR Mesajı',
  'edit.definitions.form.question1': 'Soru 1',
  'edit.definitions.form.question2': 'Soru 2',
  'edit.definitions.form.question3': 'Soru 3',
  'edit.definitions.form.question2.description': 'Açıklama',
  'edit.definitions.form.choice1': 'Seçenek 1',
  'edit.definitions.form.choice2': 'Seçenek 2',
  'edit.definitions.form.choice3': 'Seçenek 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Simge',
  'edit.definitions.icon_list.display_name': 'Simge Listesi',
  'edit.definitions.icon_list.title1': 'Madde 1',
  'edit.definitions.icon_list.body1': 'Madde 1 Açıklaması',
  'edit.definitions.icon_list.title2': 'Madde 2',
  'edit.definitions.icon_list.body2': 'Madde 2 Açıklaması',
  'edit.definitions.icon_list.title3': 'Madde 3',
  'edit.definitions.icon_list.body3': 'Madde 3 Açıklaması',
  'edit.definitions.image.display_name': 'Resim',
  'edit.definitions.image_grid.display_name': 'Çoklu Resim',
  'edit.definitions.image_grid.title1': 'Madde 1',
  'edit.definitions.image_grid.body1': 'Madde 1 Açıklaması',
  'edit.definitions.image_grid.title2': 'Madde 2',
  'edit.definitions.image_grid.body2': 'Madde 2 Açıklaması',
  'edit.definitions.image_grid.title3': 'Madde 3',
  'edit.definitions.image_grid.body3': 'Madde 3 Açıklaması',
  'edit.definitions.image_list.display_name': 'Resim Listesi',
  'edit.definitions.image_list.title1': 'Madde 1',
  'edit.definitions.image_list.body1': 'Madde 1 Açıklaması',
  'edit.definitions.image_list.title2': 'Madde 2',
  'edit.definitions.image_list.body2': 'Madde 2 Açıklaması',
  'edit.definitions.image_list.title3': 'Madde 3',
  'edit.definitions.image_list.body3': 'Madde 3 Açıklaması',
  'edit.definitions.jumbo.text': 'Yeni Jumbo Metni',
  'edit.definitions.jumbo.display_name': 'Bir metin ekleyiniz',
  'edit.definitions.label.text': 'Yeni Etiket',
  'edit.definitions.label.display_name': 'Bir metin ekleyiniz',
  'edit.definitions.line_chart.display_name': 'Çizgi Grafiği',
  'edit.definitions.line_chart.label1': 'Çizgi 1',
  'edit.definitions.line_chart.label1.name1': '09 Şub',
  'edit.definitions.line_chart.label1.name2': '10 Şub',
  'edit.definitions.line_chart.label1.name3': '11 Şub',
  'edit.definitions.line_chart.label1.name4': '12 Şub',
  'edit.definitions.line_chart.label1.name5': '13 Şub',
  'edit.definitions.line_chart.label2': 'Çizgi 2',
  'edit.definitions.line_chart.label2.name1': '09 Şub',
  'edit.definitions.line_chart.label2.name2': '10 Şub',
  'edit.definitions.line_chart.label2.name3': '11 Şub',
  'edit.definitions.line_chart.label2.name4': '12 Şub',
  'edit.definitions.line_chart.label2.name5': '13 Şub',
  'edit.definitions.links.display_name': 'Bağlantı',
  'edit.definitions.links.text': 'Buton metni',
  'edit.definitions.logo.display_name': 'Logo',
  'edit.definitions.logo_list.display_name': 'Logo Listesi',
  'edit.definitions.logo_list.title1': 'Logo 1',
  'edit.definitions.logo_list.body1': 'Logo 1 Açıklaması',
  'edit.definitions.logo_list.title2': 'Logo 2',
  'edit.definitions.logo_list.body2': 'Logo 2 Açıklaması',
  'edit.definitions.logo_list.title3': 'Logo 3',
  'edit.definitions.logo_list.body3': 'Logo 3 Açıklaması',
  'edit.definitions.pie_chart.display_name': 'Pasta Grafiği',
  'edit.definitions.pie_chart.label': 'Seri 1',
  'edit.definitions.pie_chart.value1': 'Alfa',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Echo',
  'edit.definitions.quote.display_name': 'Alıntı',
  'edit.definitions.quote.quote':
    'Hayal gücü her şeydir. Hayatın gelecek olan cazibelerinin ön izlemesidir.',
  'edit.definitions.quote.author': 'Albert Einstein',
  'edit.definitions.rating.display_name': 'Değerlendir',
  'edit.definitions.rating.title': 'Form Başlığı',
  'edit.definitions.rating.button_text': 'Gönder',
  'edit.definitions.rating.placeholder.text': 'Temsili metin',
  'edit.definitions.rating.placeholder.options': 'Temsili seçenek',
  'edit.definitions.rating.placeholder.rate': 'Temsili puanlama',
  'edit.definitions.rating.next': 'Sonraki',
  'edit.definitions.rating.back': 'Geri',
  'edit.definitions.rating.submit': 'Teşekkürler',
  'edit.definitions.rating.gdpr_consent': 'GDPR Mesajı',
  'edit.definitions.rating.question_text': 'Deneyiminizi değerlendirin!',
  'edit.definitions.rating.question_description': 'Açıklama',
  'edit.definitions.subtitle.text': 'Yeni Altyazı',
  'edit.definitions.subtitle.display_name': 'Bu bir altyazı metnidir',
  'edit.definitions.showcase.display_name': 'Cihazlar',
  'edit.definitions.table.display_name': 'Tablo',
  'edit.definitions.table.header1': 'Kategoriler',
  'edit.definitions.table.header2': 'Değerler 1',
  'edit.definitions.table.header3': 'Değerler 2',
  'edit.definitions.table.header4': 'Değerler 3',
  'edit.definitions.table.header5': 'Değerler 4',
  'edit.definitions.table.row1.value1': 'Kategori 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Kategori 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Kategori 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Kategori 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Zaman Çizelgesi',
  'edit.definitions.timeline.item1': 'Madde 1',
  'edit.definitions.timeline.description1': 'Madde 1 Açıklaması',
  'edit.definitions.timeline.item2': 'Madde 2',
  'edit.definitions.timeline.description2': 'Madde 2 Açıklaması',
  'edit.definitions.timeline.item3': 'Madde 3',
  'edit.definitions.timeline.description3': 'Madde 3 Açıklaması',
  'edit.definitions.title.text': 'Yeni Başlık',
  'edit.definitions.title.display_name': 'Bu bir başlık metnidir',
  'edit.definitions.video.display_name': 'Video',
  'edit.definitions.website.display_name': 'Web Sitesi',

  'edit.background.options': 'Arkaplan seçenekleri',

  'edit.color_mapping.swap_colors': 'Renkleri değiştir',
  'edit.color_mapping.restore': 'Varsayılana Dön',
  'edit.color_mapping.restore_short': 'Renkleri Geri Al',
  'edit.color_mapping.texts': 'Metinler',
  'edit.color_mapping.texts.item1': 'Başlık 1',
  'edit.color_mapping.texts.item2': 'Başlık 2',
  'edit.color_mapping.texts.item3': 'Alt başlık',
  'edit.color_mapping.texts.item4': 'Başlık 3',
  'edit.color_mapping.texts.item5': 'Başlık 4',
  'edit.color_mapping.background': 'Arkaplan',
  'edit.color_mapping.quote': 'Alıntı',
  'edit.color_mapping.quote.item1': 'Alıntı',
  'edit.color_mapping.quote.item2': 'Yazar',
  'edit.color_mapping.icons': 'Simgeler',
  'edit.color_mapping.icons.item1': 'Simge',
  'edit.color_mapping.icons.item2': 'Simge Arkaplan',
  'edit.color_mapping.lists': 'Listeler',
  'edit.color_mapping.lists.item1': 'Madde',
  'edit.color_mapping.lists.item2': 'Madde Başlığı',
  'edit.color_mapping.lists.item3': 'Madde Metni',
  'edit.color_mapping.table': 'Tablo',
  'edit.color_mapping.table.item1': 'Tablo İçerik',
  'edit.color_mapping.table.item2': 'Tablo Vurgulama',
  'edit.color_mapping.buttons': 'Butonlar',
  'edit.color_mapping.buttons.item1': 'Buton Metni',
  'edit.color_mapping.buttons.item2': 'Buton Arkaplanı',
  'edit.color_mapping.buttons.item3': 'Buton Çerçevesi',
  'edit.color_mapping.timeline': 'Zaman çizelgesi',
  'edit.color_mapping.timeline.item1': 'Zaman Çizelgesi Çubuğu',
  'edit.color_mapping.timeline.item2': 'Zaman Çizelgesi Noktası',
  'edit.color_mapping.form': 'Form',
  'edit.color_mapping.form.item1': 'Form Sorusu',
  'edit.color_mapping.form.item2': 'Form Buton Metni',
  'edit.color_mapping.form.item3': 'Form Buton Arkaplanı',
  'edit.color_mapping.form.item4': 'Form Simgesi',
  'edit.color_mapping.chart': 'Grafik',
  'edit.color_mapping.chart.items': 'Renk',
  'edit.color_mapping.chart.background': 'Grafik Arkaplanı',
  'edit.color_mapping.text_highlight': 'Seçili metin',
  'edit.color_mapping.text_highlight.item1': 'Metin Vurgulama',
  'edit.color_mapping.text_highlight.item2': 'Metin Vurgulama Arkaplanı',
  'edit.color_mapping.shape': 'Şekiller',
  'edit.color_mapping.shape.item1': 'Şekil Arkaplanı',
  'edit.color_mapping.decoration': 'Dekorlar',
  'edit.color_mapping.decoration.items': 'Dekor',
  // Edit page ends

  // User classify page
  'user_classify.business': 'İş',
  'user_classify.continue_with_decktopus': 'Decktopus ile devam et',
  'user_classify.customer_service': 'Müşteri hizmetleri',
  'user_classify.education': 'Eğitim',
  'user_classify.educator': 'Eğitmen',
  'user_classify.engineering': 'Mühendislik',
  'user_classify.finance': 'Finans',
  'user_classify.friends': 'Arkadaşlar',
  'user_classify.google_search': 'Google Arama',
  'user_classify.hr': 'İK',
  'user_classify.legal': 'Yasal',
  'user_classify.management': 'Yönetim',
  'user_classify.other': 'Diğer',
  'user_classify.product': 'Ürün',
  'user_classify.sales_marketing': 'Satış pazarlama',
  'user_classify.see_other_options': 'Diğer seçenekleri gör',
  'user_classify.social_media': 'Sosyal medya',
  'user_classify.student': 'Öğrenci',
  'user_classify.tell_us_about_yourself': 'Bize kendinizden bahsedin',
  'user_classify.classify_question': `Decktopus'u hangi amaçla kullanıyorsunuz?`,
  'user_classify.business_question': 'Ne tür bir iş yapıyorsunuz?',
  'user_classify.how_to_hear_about_us': 'Bizi nereden duydunuz?',
  // User classify page ends

  // Share modal
  'share_modal.embed.code': 'Gömülü kodu al',
  'share_modal.share_link': 'Bağlantı paylaş',
  'share_modal.get_share_link': 'Bağlantı linki al',
  'share_modal.make_the_share_link_public':
    'Paylaşım bağlantısını herkese açık yap',
  'share_modal.select_from_list': 'Kullanıcı listesinden seç',

  'share_modal.share_link_updated':
    'Paylaşım bağlantısı başarıyla güncellendi.',
  'share_modal.invite_people_to_collaborate': 'Projenize katılımcı davet edin',
  'share_modal.invitations_sent': 'Davetiyeler gönderildi',
  'share_modal.organization_deck_permission': 'Organizasyon izni',
  'share_modal.valid_email_message': `Lütfen geçerli bir e-posta adresi girin ve 'enter' tuşuna basın`,
  'share_modal.add_collaborators': 'Katılımcı ekle',
  'share_modal.not_collaborating_message':
    'Henüz kimseyle ortak çalışmıyorsunuz.',
  'share_modal.collaborators': 'Katılımcılar',
  'share_modal.can_edit': 'Düzenleyebilir',
  'share_modal.can_view': 'Görüntüleyebilir',
  'share_modal.embed_code': 'Gömülü kod',
  'share_modal.collaborated_users_updated': 'Ortak kullanıcılar güncellendi',
  'share_modal.save_complete': 'Değişiklikler kaydedildi',
  'share_modal.pdf_export': 'PDF olarak dışa aktar',
  'share_modal.pptx_export': 'PPTX olarak dışa aktar',
  'share_modal.latest_exports': 'Son dışa aktarmalarım',
  'share_modal.compressed_export_message':
    'Sıkıştırma işlemi slayt içindeki bileşenleri resimlere dönüştürerek dosya boyutunu azaltır, böylece depolama ve paylaşmayı kolaylaştırır.',
  'share_modal.include_slide_notes': 'Slide notlarını içersin',
  'share_modal.include_slide_notes_info_tooltip':
    'Notlar sayfa küçük resimlerinin altına eklenecektir',

  'share_modal.custom_domain.status': 'Durum',
  'share_modal.custom_domain.host': 'Alan adı',
  'share_modal.custom_domain.completed': 'TAMAMLANDI',
  'share_modal.custom_domain.pending': 'BEKLEMEDE',
  'share_modal.custom_domain.record': 'Kaydet',
  'share_modal.custom_domain.data': 'Veri',
  'share_modal.custom_domain.cname': 'Kurallı ad',
  'share_modal.custom_domain.share_link_placeholder':
    'örn. https://document.yourdomain.com',
  'share_modal.custom_domain.domain_successfully':
    'Alan adı başarıyla kaydedildi.',
  'share_modal.custom_domain.domain_deleted': 'Alan adı başarıyla silindi.',
  'share_modal.custom_domain.domain_failed':
    'Alan adı henüz doğrulanmadı. Bu işlem, alan adı sunucunuza bağlı olarak 72 saate kadar sürebilir.',
  'share_modal.custom_domain.dns_record': `Özel alan adınızı Decktopus'a bağlamak için, aşağıdaki tabloya göre DNS kayıtlarınızı güncellemeniz gerekmektedir.`,
  'share_modal.custom_domain.add_your_subdomain': 'Alt alan adınızı ekleyin:',
  'share_modal.custom_domain.info': 'Bilgi',
  'share_modal.custom_domain.verified': 'Doğrulandı',
  'share_modal.custom_domain.unverified': 'Doğrulanmadı',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Özel alan adınızı bağlayın',
  // Share modal ends

  // Admin page
  'admin.menu.user_info': 'Kullanıcı bilgisi',
  'admin.menu.back_to_search': 'Aramaya dön',
  'admin.search.welcome_support_panel':
    '<strong>Destek Paneline</strong>hoş geldiniz',
  'admin.search.you_can_search_by':
    'İhtiyacınız olanı listelemek için e-posta, sunum kimliği veya paylaşım anahtarı ile arama yapabilirsiniz.',
  'admin.search.share_key': 'Paylaşım anahtarı',
  'admin.search.deck_id': 'Sunum kimliği',
  'admin.search.user_not_found': 'Kullanıcı bulunamadı.',
  'admin.table.e_mail': 'E-posta',
  'admin.table.delete_user': 'Kullanıcıyı sil',
  'admin.table.created_date': 'Oluşturulma tarihi',
  'admin.confirm.cannot_undo_delete_user':
    'Bu işlemi geri alamazsınız. Bu kullanıcıyı silmek istediğinizden emin misiniz?',
  'admin.table.actions': 'İşlemler',
  'admin.action_menu.login_as_user': 'Kullanıcı olarak giriş yap',
  'admin.dashboard.header.back_to_support': 'Destek paneline dön',
  'admin.dashboard.header.logged_in_as_user':
    'Bir kullanıcı olarak giriş yaptınız',
  // Admin page ends

  // Table component menu items
  'table.add_row_above': 'Üstüne satır ekle',
  'table.add_row_below': 'Altına satır ekle',
  'table.add_column_left': 'Sola sütun ekle',
  'table.add_column_right': 'Sağa sütun ekle',
  'table.delete_row': 'Satırı sil',
  'table.delete_column': 'Sütunu sil',
  // Table component menu items ends

  // Payment confirmation modal
  'payment_confirmation.title': 'Planını {{ planName }} paketi ile güncelle',
  'payment_confirmation.subtitle':
    'Decktopusun ayrıcalıklı özelliklerinden sadece bir adım uzaktasınız',
  'payment_confirmation.plan': 'Plan:',
  'payment_confirmation.payment_method': 'Ödeme yöntemi:',
  'payment_confirmation.card_number': 'Kart numarası:',
  'payment_confirmation.card_date': 'Son kullanma tarihi:',
  'payment_confirmation.total': 'Toplam:',
  'payment_confirmation.contact_us':
    'Sorularınız ya da yardım talepleriniz için <0>bize ulaşın.</0>',
  'payment_confirmation.cancel': 'Ödemeyi iptal et',
  'payment_confirmation.confirm': 'Onayla ve öde',
  'payment_confirmation.seat': 'Kullanıcı',
  'payment_confirmation.pro': 'Pro AI',
  'payment_confirmation.business': 'Business AI',
  'payment_confirmation.remaining_line':
    '{{ planName }} {{ date }} tarihinden itibaren kalan zaman',
  'payment_confirmation.unused_line':
    '{{ planName }} {{ date }} tarihinden itibaren kullanılmamış zaman',
  'payment_confirmation.total_tooltip':
    'Ödemeniz mevcut fatura döngünüzün kalan günlerine göre eşit olarak dağıtılacaktır.',
  // Payment confirmation modal ends

  // AI Loading tips
  'ai_loading.text1': `Üç gün sonra insanların duyduklarının sadece %10'unu hatırladığını, ancak bir resim eklendiğinde %65'ini hatırladığını biliyor muydunuz?`,
  'ai_loading.text2':
    'Ortalama olarak, insanlar bir sunum hakkında ilk 90 saniye içinde karar verirler.',
  'ai_loading.text3':
    'Bir sunumdaki görsel araçlar, öğrenmeyi %400 oranında artırabilir.',
  'ai_loading.text4':
    'Şimdiye kadar yapılan en uzun sunum 124 saat sürdü ve 2018 yılında Hindistan’da Arvind Mishra tarafından gerçekleştirildi.',
  'ai_loading.text5':
    'Slaytlarınızda yüksek kontrast renkler kullanmak okunabilirliği %78 oranında artırabilir.',
  'ai_loading.text6': `Biliyor muydunuz? İletişimin %55'i beden dili, %38'i ses tonu ve sadece %7'si söylenen gerçek kelimelerdir.`,
  'ai_loading.text7':
    'Ortalama bir insanın dikkat süresi sadece 8 saniyedir – slaytlarınızın etkili olduğundan emin olun!',
  'ai_loading.text8':
    'İletişim İpucu: Sunumlarınızı daha hatırlanabilir hale getirmek için hikaye anlatımını kullanın.',
  'ai_loading.text9':
    'Daha basit slaytlara sahip sunumlar genellikle daha etkili olur.',
  'ai_loading.text10':
    'Sunumunuza hikayeler eklemek, onu 22 kat daha hatırlanabilir hale getirebilir.',
  'ai_loading.text11':
    'Dinleyicilerinizle göz teması kurmak, etkileşimi ve güveni artırabilir.',
  'ai_loading.text12':
    'İnsanlar duyduklarının yalnızca %10’unu üç gün sonra hala hatırlıyor, ama bir görsel eklenirse bu oran %65’e çıkıyor.',
  'ai_loading.text13':
    'Bir sunum hakkındaki fikirlerimiz ilk 90 saniyede şekillenir.',
  'ai_loading.text14':
    'Görsel materyaller öğrenmeyi %400’e kadar artırabiliyor!',
  'ai_loading.text15':
    'En uzun sunum, 2018’de Hindistan’da Arvind Mishra tarafından tam 124 saatte yapıldı.',
  'ai_loading.text16':
    'Yüksek kontrastlı slayt renkleri, okunabilirliği %78’e kadar artırabiliyor.',
  'ai_loading.text17':
    'İletişimin %55’i beden dili, %38’i ses tonu ve sadece %7’si kelimelerden oluşuyor.',
  'ai_loading.text18':
    'Ortalama dikkat süresi 8 saniyedir– slaytlarınızı hazırlarken bunu göz önünde bulundurun!',
  'ai_loading.text19':
    'İpucu: Sunumlarınızın akılda kalıcı olması için hikaye anlatımından faydalanın.',
  'ai_loading.text20':
    'Basit slaytlar çoğu zaman daha akılda kalıcı olmalarıyla bilinirler.',
  'ai_loading.text21': 'Hikayeler, sunumları 22 kat daha akılda kalıcı yapar.',
  'ai_loading.text22': 'Göz teması, izleyicinin ilgisini ve güvenini artırır.',
  // AI Loading tips ends
}
