import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IPlanCard, PLAN_CARD_THEME } from './types'
import {
  avatarStyles,
  cancelText,
  innerStyles,
  popularTag,
  wrapperStyles,
} from './styles'

import { BUTTON_THEME, BUTTON_TYPE, Button } from 'src/lib'
import { PlanDetail } from './components'
import {
  useFlags,
  useLanguage,
  useNotification,
  usePlanApi,
  useUserApi,
} from 'src/hooks'
import { NumberSelect } from 'src/lib/number-select'
import {
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
} from 'src/types/api/enums'
import teamImage from 'src/assets/images/team.svg'
import businessmanImage from 'src/assets/images/businessman.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import {
  buildPlanType,
  capitalCase,
  capitalizeFirstLetter,
} from 'src/services/stringServices'
import { sendEvent } from 'src/plugins/google/gtag'
import { GTAG_EVENTS } from 'src/plugins/google/consts'
import { getEnumKeyByValue } from 'src/helpers/index'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'

export const PlanCard: React.FC<IPlanCard> = React.memo(
  ({
    name,
    period,
    price = 0,
    currency,
    featuresTitle,
    features,
    featuresHeight,
    currentSeats = 2,
    theme = PLAN_CARD_THEME.DEFAULT,
    className,
    dataAttr,
    upgradeModal,
    context,
  }) => {
    const { t } = useLanguage()
    const [retries, setRetries] = useState(0)
    const [seatSize, setSeatSize] = useState<number>(currentSeats + 1)
    const { purchasePlan, isPurchaseLoading } = usePlanApi()
    const { success } = useNotification()
    const { getUser, getOrganizations, isLoading, getPortalUrl } = useUserApi()
    const flags = useFlags()
    const posthogCapture = usePostHogCapture()

    const { paymentState, userEmail, userPlan } = useSelector(
      ({ user }: RootState) => ({
        paymentState: user.data?.activeUserPlan?.paymentState,
        userEmail: user.data?.email,
        userPlan: user.data?.activeUserPlan,
      }),
    )

    useEffect(() => {
      setSeatSize(currentSeats + 1)
    }, [currentSeats])

    const calcPrice = useMemo(() => {
      if (period === PlanPeriods.ANNUAL) {
        return (price / 12) * seatSize
      }
      return price * seatSize
    }, [price, seatSize])

    const gtagAddToCartEvent = useCallback(() => {
      const fullPlanName =
        (getEnumKeyByValue(PlanNames, name) ?? '') +
        ' ' +
        (getEnumKeyByValue(PlanPeriods, period) ?? '')

      sendEvent(GTAG_EVENTS.ADD_TO_CART, {
        event_value: price,
        currency,
        add_to_cart_method: context,
        item_price: price,
        item_name: fullPlanName,
        user_email: userEmail,
      })
    }, [name, period, price, currency, context, userEmail])

    const handleCustomerPanelClick = useCallback(async () => {
      gtagAddToCartEvent()
      if (flags.FE_450_PAYMENT_CONFIRMATION && userPlan) {
        await purchasePlan({
          planName: name,
          planPeriod: period,
          seatSize,
          isPreview: true,
        })
      } else {
        const res = await purchasePlan({
          planName: name,
          planPeriod: period,
          seatSize,
          isPreview: false,
        })

        if (res.checkoutSessionUrl) {
          localStorage.setItem('payment-awaits', 'true')
          window.open(res.checkoutSessionUrl, '_self', 'noopener,noreferrer')
        } else {
          localStorage.removeItem('payment-awaits')
          getUserAfterPurchase()
          success('common.informative.payment_successful')
        }
      }
    }, [userPlan, name, period, seatSize, flags.FE_450_PAYMENT_CONFIRMATION])

    const shouldUpdatePaymentMethod = useMemo(() => {
      switch (paymentState) {
        case UserPlanPaymentStates.PAYMENT_PENDING:
          return true
        case UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED:
          return true
        case UserPlanPaymentStates.PAYMENT_FAILED:
          return true
        default:
          return false
      }
    }, [paymentState])

    const getUserAfterPurchase = useCallback(async () => {
      const res = await getUser()
      await getOrganizations()
      if (
        (res.user?.activeUserPlan?.paymentState ===
          UserPlanPaymentStates.PAYMENT_PENDING ||
          res.user?.activeUserPlan?.paymentState ===
            UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED) &&
        retries < 3
      ) {
        setRetries(retries + 1)
        setTimeout(() => {
          getUserAfterPurchase()
        }, 3000)
      } else {
        setRetries(0)
      }
    }, [retries, paymentState])

    const isButtonLoading = useMemo(
      () => isLoading || isPurchaseLoading,
      [isLoading, isPurchaseLoading],
    )

    const paymentButtonText = useMemo(() => {
      if (shouldUpdatePaymentMethod) {
        return t('subscriptions.update_payment')
      }
      return t(
        name === PlanNames.BUSINESS
          ? 'subscriptions.plan.choose_business'
          : 'subscriptions.plan.choose_pro',
      )
    }, [shouldUpdatePaymentMethod, name])

    const updatePaymentMethodHandler = useCallback(async () => {
      localStorage.setItem('payment-awaits', 'true')
      const res = await getPortalUrl()
      window.open(res.changePaymentMethodUrl, '_self', 'noopener,noreferrer')
    }, [])

    const paymentButtonHandler = useCallback(() => {
      if (shouldUpdatePaymentMethod) {
        updatePaymentMethodHandler()
        return
      }
      posthogCapture(POSTHOG_EVENTS.ADD_TO_CART, {
        plan_type: buildPlanType(name, period),
        from_upgrade_modal: upgradeModal,
      })

      handleCustomerPanelClick()
    }, [userPlan, name, period, seatSize])

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={avatarStyles}>
          <img
            src={name === PlanNames.BUSINESS ? teamImage : businessmanImage}
            alt="plan-image"
          />
        </div>
        {upgradeModal &&
          period == PlanPeriods.ANNUAL &&
          name == PlanNames.PRO && (
            <div css={popularTag}>
              <span className="popular-text">
                {t('subscriptions.plan.popular')}
              </span>
            </div>
          )}
        <div css={innerStyles({ theme }, upgradeModal)}>
          <div className="title">
            {capitalCase(
              `${t(`enum.planNames.${name}`)} ${t(
                `enum.planPeriods.${period}`,
              )} ${t(`common.access`)}`,
            )}
          </div>
          <div className="description">
            {capitalizeFirstLetter(
              t('subscriptions.plan.powerup_info', {
                plan_name: name ? t(`enum.planNames.${name}`) : 'common.free',
              }),
            )}
          </div>
          <div className="price">
            {currency}
            {calcPrice.toFixed(2)}
            {t('common.plan_card.month')}
            {period == PlanPeriods.ANNUAL && (
              <span className="billed-annualy">
                {t('subscriptions.billed_annually')}
              </span>
            )}
          </div>
          {name === PlanNames.BUSINESS && (
            <div>
              <NumberSelect
                min={currentSeats + 1}
                value={seatSize}
                name={t('common.seats', { count: seatSize })}
                onChange={setSeatSize}
              />
            </div>
          )}
          <div className="features-title">{featuresTitle}</div>
          <div className="plan-detail-area">
            <PlanDetail
              theme={theme}
              features={features}
              featuresHeight={featuresHeight}
              upgradeModal={upgradeModal}
            />
          </div>
          <div className="button-area">
            <Button
              onClick={paymentButtonHandler}
              text={paymentButtonText}
              theme={BUTTON_THEME.GRADIENT}
              type={
                theme === PLAN_CARD_THEME.DEFAULT
                  ? BUTTON_TYPE.DEFAULT
                  : BUTTON_TYPE.REVERSE
              }
              isLoading={isButtonLoading}
            />
          </div>
        </div>
        {upgradeModal && (
          <div css={cancelText}>{t('subscriptions.plan.cancel_anytime')}</div>
        )}
      </div>
    )
  },
)
